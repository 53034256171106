import React, { Fragment, useEffect, useState, } from 'react';
import Select, { components } from 'react-select';
import Spinner from "../layouts/Spinner.js";
import { NumberFormat } from '../../Services/_numberformat.service';
import { connect, useDispatch } from 'react-redux';
import {
    purchaseOrderPost,
    handleSelectSupplier,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputCost,
    handleInputQty,
    handleSelectItem,
    handleSelectPaymentTerms,
    handleToggleEditRow,
    handleEditInputRow,
    setModal,
    handleUpdateRow,
    handleAttrSubmit,
    getPaymentTerms,
    getSupplier,
    handleOnSubmit,
    SearchSupplier,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleSelectPaymentType,
    handleSelectPOTemplate,
    getPOTemplates,
    PODatePicker,
    clearPurchaseOrder,
    change_item,
    dynamicInputChange,
    add_items,
    searchProduct,
    handleTaxType,
    computationHandler,
    SupItemOnly,
    clear_data,
    ShowComboBox,
    getSingleProduct
} from '../../redux/actions/purchase_order/purchase_order.actions';
import PurchaseOrderBlankTable from '../layouts/table_template/PurchaseOrderBlankTable';
import { Redirect, Switch } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import PurchaseOrderCustomerModal from './modals/PurchaseOrder.supplier.modal';
import PaymentTermsModal from './modals/PaymentTerms.modal';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';
import DynamicFont from 'react-dynamic-font';
import debounce from 'debounce-promise';
import { subscriberSetting } from '../../redux/actions/settings_app/setting_app.actions';
import { numberFormat, stockClass } from '../../Utils/Common';
import moment from "moment";

function PurchaseOrderCreate({
    purchase_order: {
        single_purchase_order,
        loading,
        suppliers,
        payment_terms,
        isSearchable,
        itemSelected,
        items,
        isDiscount,
        default_product_value,
        tax_types,
        edit_purchase_order_items,
        edit_row_index,
        edit_attr,
        main_page,
        main_url,
        suppliers_default,
        supplier_modal,
        payment_terms_modal,
        payment_mode_options,
        po_default_template,
        po_templates,
        supplier_item_only,
        default_supplier_value,
    },
    handleSelectSupplier,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputCost,
    handleInputQty,
    handleSelectItem,
    handleToggleEditRow,
    handleEditInputRow,
    handleUpdateRow,
    toggleEditBtn,
    handleSelectPaymentTerms,
    setModal,
    handleAttrSubmit,
    getPaymentTerms,
    getSupplier,
    handleOnSubmit,
    SearchSupplier,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleSelectPaymentType,
    handleSelectPOTemplate,
    getPOTemplates,
    PODatePicker,
    clearPurchaseOrder,
    change_item,
    dynamicInputChange,
    add_items,
    block_route,
    blockRoute,
    searchProduct,
    subscriberSetting,
    handleTaxType,
    computationHandler,
    SupItemOnly,
    clear_data,
    ShowComboBox,
}) {
    useEffect(() => {
        async function fetchAll() {
            Promise.all([
                getPOTemplates(),
                getPaymentTerms(),
                getSupplier(),
                clearPurchaseOrder(),
                blockRoute(false),
                clear_data(),
                subscriberSetting('purchase_order'),
            ]);
        }
        fetchAll();
    }, []);

    const { purchase_order_items, discount, discount_type, tax, tax_type } =
        single_purchase_order;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(purchase_order_items),
        discount,
        discount_type,
        tax,
        tax_type,
    ]);

    const [showArrowBox, setShowArrowBox] = useState({ show: false, index: null, position: { x: 0, y: 0 } });

    // console.log(showArrowBox)

    const dispatch = useDispatch();
    // Add the handleHoverProduct function
    const handleHoverProduct = (productId, index, e) => {
        dispatch(getSingleProduct(productId));
    
        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox({
            show: true,
            index,
            position: { x: e.clientX, y: e.clientY }
        });
    };
    
    const handleMouseMove = (e) => {
        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox((prev) => ({
            ...prev,
            position: { x: e.clientX, y: e.clientY }
        }));
    };

    const handleScroll = () => {
        // Update the position of the hover box based on scroll
        setShowArrowBox((prev) => ({
            ...prev,
            position: { ...prev.position, y: prev.position.y + window.scrollY }
        }));
    };

    useEffect(() => {
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Remove scroll event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };
    const history = useHistory();
    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);

    if (main_page) return <Redirect to={main_url} />;

    const customFilter = (option, searchText) => {
        try {
            if (
                option.data.label
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.barcode
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.sub_label
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.sku
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.product_unit_name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.description
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.lot_no
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            // console.log("err", err);
            // console.log("searchText", searchText);
            // console.log("option", option);
            return false;
        }
    };

    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];
    const Option = (props) => {
        // console.log(props);
        return (
            <components.Option {...props}>
                <div>{props.data.label} [{props.data.brand.name}]</div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
                {/* <div style={{ fontSize: 11 }}>{props.data.lot_no}</div>
                <div style={{ fontSize: 11 }}>{props.data.brand}</div> */}
            </components.Option>
        );
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
        }),
        control: (provided) => ({
            ...provided,
        }),
        singleValue: (provided) => ({
            ...provided,
        }),
    };

    // console.log("single purchase order", single_purchase_order.purchase_order_items)

    return (
        <BeforeUnloadComponent
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row ">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Purchases
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item">
                                            <a
                                                href={main_url}
                                                className="text-white"
                                            >
                                                Purchase Orders
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#" className="text-white">
                                                Create
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples ">
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left"></i>{' '}
                                                Back
                                            </Link>
                                            <a className="heading-elements-toggle">
                                                {' '}
                                                <i className="la la-ellipsis-v font-medium-3"></i>
                                            </a>
                                            <div className="heading-elements">
                                                {' '}
                                                <ul className="list-inline mb-0"></ul>
                                            </div>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body pb-10m">
                                                <form>
                                                    <section className="f-regular">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-sm-4">
                                                                        <label>
                                                                            Template{' '}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <Select
                                                                                    options={
                                                                                        po_templates
                                                                                    }
                                                                                    onChange={
                                                                                        handleSelectPOTemplate
                                                                                    }
                                                                                    value={
                                                                                        po_default_template
                                                                                    }
                                                                                    isSearchable={
                                                                                        isSearchable
                                                                                    }
                                                                                    required
                                                                                />
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4"></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-4">
                                                                        <label>
                                                                            Supplier{' '}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>{' '}
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <AsyncSelect
                                                                                    isLoading={
                                                                                        loading
                                                                                    }
                                                                                    value={
                                                                                        default_supplier_value
                                                                                    }
                                                                                    loadingMessage={() =>
                                                                                        'searching...'
                                                                                    }
                                                                                    noOptionsMessage={({ inputValue }) =>
                                                                                        inputValue ? 'No Supplier' : 'Search Supplier'
                                                                                    }
                                                                                    defaultOptions={
                                                                                        suppliers_default
                                                                                    }
                                                                                    loadOptions={
                                                                                        SearchSupplier
                                                                                    }
                                                                                    isSearchable={
                                                                                        true
                                                                                    }
                                                                                    cacheOptions
                                                                                    onChange={
                                                                                        handleSelectSupplier
                                                                                    }
                                                                                    placeholder="Select Supplier..."
                                                                                    required
                                                                                />
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                    <Show when="inv.purchases.suppliers.create">
                                                                                        <div
                                                                                            className="a-tagicon icon-s"
                                                                                            onClick={() =>
                                                                                                setModal(
                                                                                                    'supplier_modal',
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft ft-plus-circle text-primary"></i>
                                                                                        </div>
                                                                                    </Show>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label className="form-check-label font-medium-2 text-bold-600 ml-1">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="switchery"
                                                                                    checked={
                                                                                        supplier_item_only
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        SupItemOnly(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />{' '}
                                                                                Only
                                                                                Show
                                                                                Items
                                                                                by
                                                                                Supplier
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4"></div>
                                                                    <div className="col-sm-4">
                                                                        <label>
                                                                            PO
                                                                            Date
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <div className="date-picker-100">
                                                                                    <DatePicker
                                                                                        onChange={(
                                                                                            date
                                                                                        ) =>
                                                                                            PODatePicker(
                                                                                                date
                                                                                            )
                                                                                        }
                                                                                        selected={
                                                                                            single_purchase_order.purchase_order_date
                                                                                        }
                                                                                        name="purchase_order_date"
                                                                                        className="form-control f-regular"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="table-responsive">
                                                                         
                                                                                <Fragment>
                                                                                    <table className="table table-hover table-striped table-bordered table-responsive f-regular">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className="text-center td-16m">
                                                                                                    SKU
                                                                                                </th>
                                                                                                <th className="text-center">
                                                                                                    Product
                                                                                                </th>
                                                                                                <th className="text-center mw-6m">
                                                                                                    Qty
                                                                                                </th>
                                                                                                <th className="text-center">
                                                                                                    Unit
                                                                                                </th>
                                                                                                <th className="text-center mw-8m">
                                                                                                    Unit/Price
                                                                                                </th>
                                                                                                <th className="text-center">
                                                                                                    Amount
                                                                                                </th>
                                                                                                <th className="text-center mw-2m">
                                                                                                    Action
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            
                                                                                      { 
                                                                                      
                                                                                      loading ? (
                                                                                        <tr>
                                                                                        <td
                                                                                            colSpan={ 9 }
                                                                                            className='text-center'
                                                                                        >
                                                                                            <Spinner />
                                                                                        </td>
                                                                                        </tr>
                                                                                        ) : single_purchase_order.purchase_order_items.length === 0 ? (
                                                                                               <tr align="center" className="bg-warning bg-accent-2">
                                                                                                    <td colSpan={7}>
                                                                                                        <span>-- Please select Supplier first --</span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                        ) : single_purchase_order.purchase_order_items.map(
                                                                                                (
                                                                                                    item,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <Fragment
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        {' '}
                                                                                                        {/* This is the key part */}
                                                                                                        <tr
                                                                                                            className={stockClass(
                                                                                                                item.status_code
                                                                                                            )}
                                                                                                        >
                                                                                                      <td className="table-data__sku ">
                                                                                                                {
                                                                                                                    item.sku
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td className="min-wdth-300 max-wdth-300">
                                                                                                                {item.selected ? (
                                                                                                                    <div>
                                                                                                                        {/* <div className="f-right" onClick={() =>
                                                                                                                            ShowComboBox(
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }>
                                                                                                                            <i className='ft ft-chevron-down c-pointer'></i>
                                                                                                                        </div> */}
                                                                                                                        <div className="mw-90">
                                                                                                                        <Link
                                                                                                                            to={`/inventory/view/${item._id}`}
                                                                                                                            target="_blank"
                                                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                                                            onMouseEnter={(e) => handleHoverProduct(item._id, index, e)}
                                                                                                                            onMouseMove={(e) => handleMouseMove(e)}
                                                                                                                            onMouseLeave={() => setShowArrowBox({ show: false, index: null, position: { x: 0, y: 0 } })}
                                                                                                                        >
                                                                                                                            {item.product_name}
                                                                                                                        </Link>

                                                                                                                        {showArrowBox.show && showArrowBox.index === index && (
                                                                                                                            <div className="arrow-box" style={{ top: `${showArrowBox.position.y}px`, left: `${showArrowBox.position.x}px`, position: 'fixed' }}>
                                                                                                                                <p>Incoming: <span className='font-weight-bold'>{item.productDetails.incoming_orders}</span></p>
                                                                                                                                    <p>Current stock: <span className='font-weight-bold'>{item.productDetails.total_stocks}</span></p>
                                                                                                                                    <p>Last arrival: <span className='font-weight-bold'>{moment(item.productDetails.last_arrival).isValid() ? moment(item.productDetails.last_arrival).format("YYYY-MM-DD") : "N/A"}</span></p>
                                                                                                                                </div>
                                                                                                                            )}

                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.barcode
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.description
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.brand.name
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <AsyncSelect
                                                                                                                        isLoading={
                                                                                                                            loading
                                                                                                                        }
                                                                                                                        value={{
                                                                                                                            label: item.product_name,
                                                                                                                            value: item._id,
                                                                                                                        }}
                                                                                                                        components={{
                                                                                                                            Option,
                                                                                                                        }}
                                                                                                                        classNamePrefix="lp-copy-sel"
                                                                                                                        loadingMessage={() =>
                                                                                                                            'searching...'
                                                                                                                        }
                                                                                                                        noOptionsMessage={({ inputValue }) =>
                                                                                                                        inputValue ? 'No Products' : 'Search product'
                                                                                                                        }
                                                                                                                        defaultOptions={
                                                                                                                            item.items
                                                                                                                        }
                                                                                                                        loadOptions={debounce(
                                                                                                                            (
                                                                                                                                value
                                                                                                                            ) =>
                                                                                                                                searchProduct(
                                                                                                                                    value,
                                                                                                                                    index
                                                                                                                                ),
                                                                                                                                1000
                                                                                                                        )}
                                                                                                                        isSearchable={
                                                                                                                            true
                                                                                                                        }
                                                                                                                        onChange={(
                                                                                                                            val
                                                                                                                        ) =>
                                                                                                                            change_item(
                                                                                                                                val,
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }
                                                                                                                        placeholder="Select Products..."
                                                                                                                        required
                                                                                                                    />
                                                                                                                )}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                className="mw-6m"
                                                                                                                align="center"
                                                                                                            >
                                                                                                                <input
                                                                                                                    value={
                                                                                                                        item.qty
                                                                                                                    }
                                                                                                                    className="form-control text-center"
                                                                                                                    name="qty"
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        dynamicInputChange(
                                                                                                                            e,
                                                                                                                            index
                                                                                                                        )
                                                                                                                    }
                                                                                                                    type="number"
                                                                                                                    step="any"
                                                                                                                    min={
                                                                                                                        1
                                                                                                                    }
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td className="min-wdth-75 max-wdth-100 text-center">
                                                                                                                {
                                                                                                                    item.product_unit_name
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td className="mw-8m text-right">
                                                                                                                <input
                                                                                                                    value={
                                                                                                                        item.cost
                                                                                                                    }
                                                                                                                    className="form-control text-right"
                                                                                                                    name="cost"
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        dynamicInputChange(
                                                                                                                            e,
                                                                                                                            index
                                                                                                                        )
                                                                                                                    }
                                                                                                                    type="number"
                                                                                                                    step="any"
                                                                                                                    min={
                                                                                                                        0
                                                                                                                    }
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td
                                                                                                                className="min-wdth-150"
                                                                                                                align="right"
                                                                                                            >
                                                                                                                {item.amount
                                                                                                                    ? NumberFormat(
                                                                                                                          item.amount.toFixed(
                                                                                                                              2
                                                                                                                          )
                                                                                                                      )
                                                                                                                    : NumberFormat(
                                                                                                                          item.cost.toFixed(
                                                                                                                              2
                                                                                                                          )
                                                                                                                      )}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                className="mw-2m"
                                                                                                            >
                                                                                                                <span className="m-1">
                                                                                                                    <button
                                                                                                                        className="btn btn-sm btn-primary"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            handleDiscountItem(
                                                                                                                                index,
                                                                                                                                false
                                                                                                                            )
                                                                                                                        }
                                                                                                                        type="button"
                                                                                                                    >
                                                                                                                        <i className="ft ft-tag"></i>
                                                                                                                    </button>
                                                                                                                </span>

                                                                                                                <span>
                                                                                                                    {/* {edit_row_index == index && <a className="btn btn-sm btn-primary" onClick={e => handleUpdateRow(index)}href="#"><i className="ft ft-check"></i></a> } */}
                                                                                                                    {/* {edit_row_index != index && <a className="btn btn-sm btn-grey" onClick={e => handleToggleEditRow(index)} href="#"><i className="ft ft-edit"></i></a>} */}
                                                                                                                    <button
                                                                                                                        className="btn btn-sm btn-danger"
                                                                                                                        type="button"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            handleRemoveItem(
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="ft ft-trash"></i>
                                                                                                                    </button>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        {item.showDiscountRow && (
                                                                                                            <tr>
                                                                                                                <td
                                                                                                                    colSpan={
                                                                                                                        1
                                                                                                                    }
                                                                                                                ></td>
                                                                                                                <td
                                                                                                                    className="d-flex ali justify-content-center align-items-center"
                                                                                                                    style={{
                                                                                                                        gap: '5%',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span>
                                                                                                                        Discount:
                                                                                                                    </span>

                                                                                                                    <span className="w-20 table-data__discount">
                                                                                                                        {(() => {
                                                                                                                            switch (
                                                                                                                                item
                                                                                                                                    .discount_type
                                                                                                                                    .value
                                                                                                                            ) {
                                                                                                                                case 'percent':
                                                                                                                                    return (
                                                                                                                                        <div class="input-group">
                                                                                                                                            <input
                                                                                                                                                onChange={(
                                                                                                                                                    e
                                                                                                                                                ) =>
                                                                                                                                                    handleInputDiscountProduct(
                                                                                                                                                        e,
                                                                                                                                                        index
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                value={
                                                                                                                                                    item.discount
                                                                                                                                                }
                                                                                                                                                name="discount"
                                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                                type="number"
                                                                                                                                            />
                                                                                                                                            <div class="input-group-append">
                                                                                                                                                <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                                    %
                                                                                                                                                </span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    );
                                                                                                                                case 'amount':
                                                                                                                                    return (
                                                                                                                                        <input
                                                                                                                                            onChange={(
                                                                                                                                                e
                                                                                                                                            ) =>
                                                                                                                                                handleInputDiscountProduct(
                                                                                                                                                    e,
                                                                                                                                                    index
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            value={
                                                                                                                                                item.discount
                                                                                                                                            }
                                                                                                                                            name="discount"
                                                                                                                                            className="form-control text-right f-regular"
                                                                                                                                            type="number"
                                                                                                                                        />
                                                                                                                                    );
                                                                                                                                default: // 0 disabled
                                                                                                                                    return (
                                                                                                                                        <input
                                                                                                                                            value={
                                                                                                                                                item.discount
                                                                                                                                            }
                                                                                                                                            disabled
                                                                                                                                            name="discount"
                                                                                                                                            className="form-control text-right f-regular"
                                                                                                                                            type="number"
                                                                                                                                        />
                                                                                                                                    );
                                                                                                                            }
                                                                                                                        })()}
                                                                                                                    </span>
                                                                                                                    <span className="table-data__discount-dropdown">
                                                                                                                        <Select
                                                                                                                            name="discount_type"
                                                                                                                            onChange={(
                                                                                                                                selectOption
                                                                                                                            ) =>
                                                                                                                                handleSelectDiscountTypeProduct(
                                                                                                                                    selectOption,
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                            menuPortalTarget={
                                                                                                                                document.body
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                item.discount_type
                                                                                                                            }
                                                                                                                            defaultValue={
                                                                                                                                item.discount_type
                                                                                                                            }
                                                                                                                            label="Single select"
                                                                                                                            options={
                                                                                                                                percent_value
                                                                                                                            }
                                                                                                                            isSearchable={
                                                                                                                                false
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td></td>
                                                                                                                <td></td>
                                                                                                                <td></td>
                                                                                                                <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                                    -{' '}
                                                                                                                    {NumberFormat(
                                                                                                                        Number(
                                                                                                                            item.discount_amount
                                                                                                                        ).toFixed(
                                                                                                                            2
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </td>

                                                                                                                <td
                                                                                                                    align="center"
                                                                                                                    className="mw-2m"
                                                                                                                >
                                                                                                                    <button
                                                                                                                        className="btn btn-sm btn-danger"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            handleDiscountItem(
                                                                                                                                index,
                                                                                                                                true
                                                                                                                            )
                                                                                                                        }
                                                                                                                        type="button"
                                                                                                                    >
                                                                                                                        <i className="ft ft-trash"></i>
                                                                                                                    </button>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )}
                                                                                                    </Fragment>
                                                                                                )
                                                                                            )}

                                                                                            
                                                                                            
                                                                                            {single_purchase_order.purchase_order_items.length != 0 ? (
                                                                                                  <tfoot>
                                                                                                      <tr>
                                                                                                <td
                                                                                                    colSpan="7"
                                                                                                    className="text-left"
                                                                                                >
                                                                                                    <button
                                                                                                        className="btn btn-primary"
                                                                                                        type="button"
                                                                                                        onClick={
                                                                                                            add_items
                                                                                                        }
                                                                                                    >
                                                                                                        <i className="la la-plus"></i>
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                                  </tfoot>
                                                                                                ) : (
                                                                                                  <tfoot></tfoot>
                                                                                                )
                                                                                              // ) : (
                                                                                              //   <tfoot></tfoot>
                                                                                              // )
                                                                                              }
                                                                                         
                                                                                            <tr>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                                <td className="p-2">
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    colSpan={
                                                                                                        4
                                                                                                    }
                                                                                                ></td>
                                                                                                <td>
                                                                                                    <div align="right">
                                                                                                        Sub
                                                                                                        Total
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td align="right">
                                                                                                    {NumberFormat(
                                                                                                        single_purchase_order.sub_total.toFixed(
                                                                                                            2
                                                                                                        )
                                                                                                    )}
                                                                                                </td>
                                                                                                <td></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    colSpan={
                                                                                                        4
                                                                                                    }
                                                                                                ></td>
                                                                                                <td>
                                                                                                    <div align="right">
                                                                                                        Discount
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td
                                                                                                    width="12%"
                                                                                                    className="min-width-150"
                                                                                                >
                                                                                                    {(() => {
                                                                                                        switch (
                                                                                                            single_purchase_order
                                                                                                                .discount_type
                                                                                                                .value
                                                                                                        ) {
                                                                                                            case 'percent':
                                                                                                                return (
                                                                                                                    <div class="input-group">
                                                                                                                        <input
                                                                                                                            onChange={
                                                                                                                                handleInputChangeDiscount
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                single_purchase_order.discount
                                                                                                                            }
                                                                                                                            name="discount"
                                                                                                                            className="form-control text-right f-regular"
                                                                                                                            type="number"
                                                                                                                        />
                                                                                                                        <div class="input-group-append">
                                                                                                                            <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                %
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            case 'amount':
                                                                                                                return (
                                                                                                                    <input
                                                                                                                        onChange={
                                                                                                                            handleInputChangeDiscount
                                                                                                                        }
                                                                                                                        value={
                                                                                                                            single_purchase_order.discount
                                                                                                                        }
                                                                                                                        name="discount"
                                                                                                                        className="form-control text-right f-regular"
                                                                                                                        type="number"
                                                                                                                    />
                                                                                                                );
                                                                                                            default: // 0 disabled
                                                                                                                return (
                                                                                                                    <input
                                                                                                                        value={
                                                                                                                            single_purchase_order.discount
                                                                                                                        }
                                                                                                                        disabled
                                                                                                                        name="discount"
                                                                                                                        className="form-control text-right f-regular"
                                                                                                                        type="number"
                                                                                                                    />
                                                                                                                );
                                                                                                        }
                                                                                                    })()}
                                                                                                </td>
                                                                                                <td className="text-center padding-td-5 min-width-150">
                                                                                                    <Select
                                                                                                        name="discount_type"
                                                                                                        onChange={
                                                                                                            handleSelectDiscountType
                                                                                                        }
                                                                                                        menuPortalTarget={
                                                                                                            document.body
                                                                                                        }
                                                                                                        value={
                                                                                                            single_purchase_order.discount_type
                                                                                                        }
                                                                                                        defaultValue={
                                                                                                            single_purchase_order.discount_type
                                                                                                        }
                                                                                                        label="Single select"
                                                                                                        options={
                                                                                                            percent_value
                                                                                                        }
                                                                                                        isSearchable={
                                                                                                            false
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td
                                                                                                    colSpan={
                                                                                                        4
                                                                                                    }
                                                                                                ></td>
                                                                                                <td>
                                                                                                    <div align="right">
                                                                                                        VAT
                                                                                                        (
                                                                                                        {
                                                                                                            single_purchase_order.tax
                                                                                                        }{' '}
                                                                                                        %)
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td align="right">
                                                                                                    {NumberFormat(
                                                                                                        single_purchase_order.vat_amount.toFixed(
                                                                                                            2
                                                                                                        )
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="padding-td-5 min-width-150">
                                                                                                    <Select
                                                                                                        name="tax_type"
                                                                                                        onChange={
                                                                                                            handleTaxType
                                                                                                        }
                                                                                                        value={
                                                                                                            single_purchase_order.tax_type
                                                                                                        }
                                                                                                        menuPortalTarget={
                                                                                                            document.body
                                                                                                        }
                                                                                                        options={
                                                                                                            tax_types
                                                                                                        }
                                                                                                        isSearchable={
                                                                                                            false
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td colSpan="4"></td>
                                                                                                <td>
                                                                                                    <div align="right">
                                                                                                        <strong>
                                                                                                            Total
                                                                                                        </strong>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td align="right">
                                                                                                    {NumberFormat(
                                                                                                        single_purchase_order.total.toFixed(
                                                                                                            2
                                                                                                        )
                                                                                                    )}
                                                                                                </td>
                                                                                                <td></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Fragment>
                                                                              
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-2"></div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <label>
                                                                            Remarks{' '}
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <textarea
                                                                                    onChange={
                                                                                        handleInputChange
                                                                                    }
                                                                                    placeholder="Remarks"
                                                                                    rows="2"
                                                                                    name="remarks"
                                                                                    className="form-control f-regular"
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-4">
                                                                        <label>
                                                                            Payment
                                                                            Mode
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <Select
                                                                                    options={
                                                                                        payment_mode_options
                                                                                    }
                                                                                    onChange={
                                                                                        handleSelectPaymentType
                                                                                    }
                                                                                    value={
                                                                                        single_purchase_order.payment_mode
                                                                                    }
                                                                                    isSearchable={
                                                                                        isSearchable
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <label>
                                                                            Payment
                                                                            Terms{' '}
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <Select
                                                                                    options={
                                                                                        payment_terms
                                                                                    }
                                                                                    onChange={
                                                                                        handleSelectPaymentTerms
                                                                                    }
                                                                                    value={
                                                                                        single_purchase_order.payment_terms
                                                                                    }
                                                                                    isSearchable={
                                                                                        isSearchable
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                    <div
                                                                                        className="a-tagicon icon-s"
                                                                                        onClick={() =>
                                                                                            setModal(
                                                                                                'payment_terms_modal',
                                                                                                true
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i className="ft ft-plus-circle text-primary"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </form>
                                                <div className="row justify-content-end mt-3">
                                                    <div className="mr-2">
                                                        <div className="form-group">
                                                            <Show when="inv.purchases.purchase-orders.draft">
                                                                <div className="btn-group btn-dpd">
                                                                    <button
                                                                        className="btn btn-default btn-outline-light text-dark btn-main"
                                                                        onClick={() =>
                                                                            handleOnSubmit(
                                                                                'create',
                                                                                'draft'
                                                                            )
                                                                        }
                                                                        type="button"
                                                                    >
                                                                        Save as
                                                                        Draft
                                                                    </button>
                                                                    <button
                                                                        data-toggle="dropdown"
                                                                        className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                        type="button"
                                                                    >
                                                                        <span className="caret"></span>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {/* <li><a className="dpd-menu" onClick={() => handleOnSubmit('create', 'draft')}>Daft</a></li> */}
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'create',
                                                                                        'draft-email'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Email
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'create',
                                                                                        'draft-print'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Print
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Show>{' '}
                                                            <div className="btn-group btn-dpd">
                                                                <button
                                                                    className="btn btn-primary  btn-main"
                                                                    onClick={() =>
                                                                        handleOnSubmit(
                                                                            'create',
                                                                            'saved'
                                                                        )
                                                                    }
                                                                    type="button"
                                                                >
                                                                    Save Purchase
                                                                </button>
                                                                <button
                                                                    data-toggle="dropdown"
                                                                    className="btn btn-primary dropdown-toggle btn-carret"
                                                                    type="button"
                                                                >
                                                                    <span className="caret"></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    {/* {
                                                                    (edit_row_index != null || isDiscount) &&
                                                                    <li><a className="dpd-menu" href="#" onClick={() => handleOnSubmit('create', 'saved')}>Save</a></li>
                                                                } */}
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                handleOnSubmit(
                                                                                    'create',
                                                                                    'saved-email'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Email
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                handleOnSubmit(
                                                                                    'create',
                                                                                    'saved-print'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Print
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <PurchaseOrderCustomerModal
                    show={supplier_modal}
                    onHide={() => setModal('supplier_modal', false)}
                />
                <PaymentTermsModal
                    show={payment_terms_modal}
                    onHide={() => setModal('payment_terms_modal', false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    purchase_order: state.purchase_order,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    purchaseOrderPost,
    toggleEditBtn,
    handleSelectSupplier,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputCost,
    handleInputQty,
    handleSelectItem,
    handleSelectPaymentTerms,
    handleAttrSubmit,
    handleToggleEditRow,
    handleEditInputRow,
    handleUpdateRow,
    handleAttrSubmit,
    getPaymentTerms,
    getSupplier,
    handleOnSubmit,
    SearchSupplier,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    setModal,
    handleSelectPaymentType,
    blockRoute,
    handleSelectPOTemplate,
    getPOTemplates,
    PODatePicker,
    clearPurchaseOrder,
    change_item,
    dynamicInputChange,
    add_items,
    searchProduct,
    subscriberSetting,
    handleTaxType,
    computationHandler,
    SupItemOnly,
    clear_data,
    ShowComboBox,
    getSingleProduct
})(PurchaseOrderCreate);
