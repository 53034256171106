import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import {
    setPage,
    getProductUnits,
    handleSearchInputMUI,
    sortTableByColumn,
    getSelectedRows,
    confirmDeleteDialogBox,
} from './../../redux/actions/product_unit/product_unit.actions';
import ReactTooltip from 'react-tooltip';
import { formattedDate, truncateObjectId } from '../../Utils/Common';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import moment from 'moment';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

function ProductUnit({
    product_unit: {
        loading,
        pagination,
        search,
        product_units,
        create_url,
        edit_page,
        edit_url,
        sort_order_direction,
        sort_order_name,
        set_row_checked,
    },
    getProductUnits,
    handleSearchInputMUI,
    setPage,
    sortTableByColumn,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    hoverIndex,
    updateMUISetting,
    setMUIPage,
    muiPage,
}) {
    const { hasPermission } = useAccess();
    // const canCreate = hasPermission("inv.products.product-unit.create");
    const canDelete = hasPermission('inv.setting.product-unit.delete');
    const canEdit = hasPermission('inv.setting.product-unit.edit');
    // const canview = hasPermission("inv.products.product-unit.view");

    const history = useHistory();

    useMemo(() => {
        getProductUnits(muiPage);
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    getProductUnits(1);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    const [copiedId, setCopiedId] = useState(null);

    if (edit_page) return <Redirect to={edit_url} />;

    const changePage = (link) => {
        history.push(link);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(2)': {
                            height: '61px',
                            width: '300px',
                        },
                        '&:nth-child(3)': {
                            width: '300px',
                        },
                        '&:nth-child(4)': {
                            width: '300px',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: '_id',
            label: 'ID',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                customBodyRender: (_, tableMeta) => {
                  const productUnitId = product_units[tableMeta['rowIndex']]._id;
                  return (
                      <div style={{ whiteSpace: 'nowrap' }}>
                          <span>{truncateObjectId(productUnitId)}</span>
                          {hoverIndex === tableMeta.rowIndex ? (
                              <span style={{ marginLeft: '15px' }}>
                                  <span
                                      style={{ position: 'relative' }}
                                      onMouseLeave={() =>
                                          setTimeout(
                                              () => setCopiedId(null),
                                              230
                                          )
                                      }
                                  >
                                      <CopyToClipboard
                                          text={productUnitId}
                                          onCopy={() => {
                                              setCopiedId(productUnitId);
                                          }}
                                      >
                                          {/* <FontAwesomeIcon
                                              icon={faClipboard}
                                              // style={{ color: '#32cafe' }}
                                              style={{ color: 'blue' }}
                                              size="lg"
                                          /> */}
                                            <i  className="ft-copy" style={{fontSize: "14px"}}></i>
                                      </CopyToClipboard>
                                      {copiedId === productUnitId ? (
                                          <span
                                              style={{
                                                  position: 'absolute',
                                                  left: '-20px',
                                                  width: '60px',
                                                  height: '60px',
                                              }}
                                          >
                                              <a
                                                  data-tip="Copied!"
                                                  data-for={`copied-${productUnitId}`}
                                                  style={{
                                                      display: 'inline-block',
                                                      width: '100%',
                                                  }}
                                              >
                                                  &nbsp;
                                              </a>
                                              <ReactTooltip
                                                  id={`copied-${productUnitId}`}
                                                  type="success"
                                                  effect="solid"
                                                  scrollHide={false}
                                                  resizeHide={false}
                                                  place="top"
                                                  delayHide={5000}
                                              />
                                          </span>
                                      ) : (
                                          ''
                                      )}
                                  </span>
                              </span>
                          ) : (
                              ''
                          )}
                      </div>
                  );
              },
            },
        },
        { name: 'name', label: 'Name', options: { display: true } },
        {
            name: 'description',
            label: 'Description',
            options: { display: true },
        },
        // {
        //   name: "created_at",
        //   label: "Created at",
        //   options: {
        //     display: true,
        //     print: false,
        //     download: false,
        //     customBodyRender: (value, tableMeta, updateValue) => {
        //       let created_at = product_units[tableMeta["rowIndex"]].created_at;
        //       return <>{moment(created_at).format("YYYY-MM-DD - hh:mmA")}</>;
        //     },
        //   },
        // },
        // {
        //   name: "updated_at",
        //   label: "Updated at",
        //   options: {
        //     display: true,
        //     print: false,
        //     download: false,
        //     customBodyRender: (value, tableMeta, updateValue) => {
        //       let updated_at = product_units[tableMeta["rowIndex"]].updated_at;
        //       return <>{moment(updated_at).format("YYYY-MM-DD - hh:mmA")}</>;
        //     },
        //   },
        // },
        {
            name: 'is_default',
            label: 'Action',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = product_units[tableMeta['rowIndex']]._id;
                    let is_default_col =
                        product_units[tableMeta['rowIndex']].is_default;

                    if (!canEdit) {
                        return false;
                    }

                    // if is_default is not defined
                    if (!is_default_col) {
                        return (
                            <>
                                {hoverIndex == tableMeta.rowIndex && (
                                    <Link
                                        to={`${edit_url}/${id}`}
                                        className="btn btn-sm btn-grey"
                                    >
                                        <i className="ft ft-edit"></i>
                                    </Link>
                                )}
                            </>
                        );
                    }

                    // disabled the edit if field is a default template
                    return (
                        <div className="btn-group">
                            {hoverIndex == tableMeta.rowIndex && (
                                <span className="btn btn-sm btn-grey disabled">
                                    <i className="ft ft-edit"></i>
                                </span>
                            )}
                        </div>
                    );
                },
                // setCellProps: (value) => {
                //     return { className: 'text-center' };
                // },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        filter: false,
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        elevation: 2,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `product_unit_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getProductUnits(null, numberOfRows);
            updateMUISetting('rows', 'product_unit', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getProductUnits(
                        tableState.page + 1,
                        pagination.itemsCountPerPage
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'product_unit',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                // console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox();
            }

            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // Go to edit page when row is clicked
            if (canEdit) {
                // changePage(`${edit_url}/${rowData[0]}`);
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onChange={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'product_unit',
        null,
        columns
    );

    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            {' '}
                            Setting{' '}
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                        <a
                                            href="/setting/product-unit"
                                            className="text-white"
                                        >
                                            {' '}
                                            Product Units{' '}
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">
                                            List of Product Units{' '}
                                            <a
                                                href="#"
                                                className="tool-tip"
                                                data-tip="this is a tool tip"
                                            >
                                                <i className="la la-question-circle"></i>
                                            </a>
                                            <Show when="inv.setting.product-unit.create">
                                                <Link
                                                    to={create_url}
                                                    className="btn btn-primary pull-right btn-sm"
                                                >
                                                    <i className="ft ft-plus"></i>{' '}
                                                    Create New
                                                </Link>
                                            </Show>
                                        </h4>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                            <Show when="inv.setting.product-unit.view">
                                                <MuiThemeProvider
                                                    theme={getMuiTheme()}
                                                >
                                                    <MUIDataTable
                                                        data={product_units}
                                                        columns={_columns}
                                                        options={options}
                                                    />
                                                </MuiThemeProvider>
                                            </Show>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    product_unit: state.product_unit,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    setPage,
    getProductUnits,
    handleSearchInputMUI,
    sortTableByColumn,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    setMUIPage,
})(ProductUnit);
