import * as TYPES from '../types';

// state
const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        totalItemsCount: 10,
    },
    search: '',
    daily_sales: [],
    sales: [],
    single_daily_sales: {},
    sort_order_name: null,
    sort_order_direction: null,
    loading: false,
    modalLoading: false,
    lotno_modal: false,
    selected_products: [],
    date_from: new Date(),
    date_to: new Date(),
};


const dailySalesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_DAILY_SALES_DATA:
            return {
                ...state,
                daily_sales: action.payload.daily_sales,
                pagination: action.payload.pagination,
            };
        case TYPES.SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case TYPES.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case TYPES.SET_MODAL_LOADING: // New case for modal loading
            return {
                ...state,
                modalLoading: action.payload,
            };
        case TYPES.DAILY_SALES_VIEW:
            return {
                ...state,
                daily_sales: action.payload.daily_sales,
            };
        case TYPES.PRODUCTS_DAILY_SALES:
            return {
                ...state,
                selected_products: action.payload,
            };

        case TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case TYPES.DATE_FILTER_INPUT:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        case TYPES.DAILY_SALES_DATE_INPUT_CLEAR:
            return {
                ...state,
                date_from: new Date(),
                date_to: new Date(),
                date_filter: false,
                display_tab: { ...state.display_tab, [action.payload]: true },
            };
        case TYPES.DAILY_SALES_CHECKER:
            return {
                ...state,
                sales: action.payload.sales
            }
        default:
            return state;
            break;
    }
};

export default dailySalesReducer;
