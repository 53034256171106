import http from '../../../Services/api.laravel.http';
import httpFile from '../../../Services/api.laravel.httpWithFile';

export const ProductService = {
    // getBrands: () => http.get('/admin/brand'),
    getSingleProduct: (id) => http.get('/admin/product/' + id),
    ArchiveProduct: (formParams) =>
        http.put('/admin/product/archives/list', formParams),
    productPost: (formParams) => http.post('/admin/product', formParams),
    updateSingleProduct: (formParams, id) =>
        http.put('/admin/product/update/' + id, formParams),
    paginateWithSearchProducts: (formParams) =>
        http.post('/admin/product/search', formParams),
    getSelectAll: () => http.get('/admin/product/select/detail'), // get names of brand,category,supplier
    getAttributeTemplates: (id) =>
        http.get('/admin/product/select/attribute-templates/' + id), // get the attribute templates
    getSingleAttribute: (id) => http.get('/admin/attribute/' + id), // get the attributes base on id,
    skuSkipValidation: (formParams) =>
        http.post('/admin/product/validate/sku', formParams), // sku skip validation
    // updateItemTags: (formParams) => http.post('/admin/product/item-tags/update/list', formParams), // update item tags (old)
    updateItemTags: (formParams) =>
        http.post('/admin/product/product-tags', formParams), // update item tags (new)
    exportProducts: () => httpFile.get('/excel/product/export'), // export product
    checkBarcodeValidation: (formParams) =>
        http.post('/admin/product/validate/barcode', formParams), // check copied sku to barcode
    downloadTemplate: () =>
        httpFile.get('/excel/product/template', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        }),
    importProduct: (file, onUploadProgress) =>
        http.post('/excel/product/import', file, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress,
        }),
    verifyPassword: (formdata) => http.post('/user/password/verify', formdata),
    checkAddSupplier: (formdata) =>
        http.post('/admin/product/check-add-supplier', formdata),
};
