import * as TYPE from "../../types";
import $ from "jquery";
import { SwalWarning } from "../../../Services/_swal.service";
import { ToastSuccess, ToastDanger } from "../../../Services/_toast.service";
import { HelperService } from "./_service.helper";
import { getMUIDataSetting, getUserData } from "../../../Utils/Common";
import { poClearUpload } from "../purchase_order/purchase_order.actions";
import { quClearUpload } from "../quotation/quotation.actions";
import { siClearUpload } from "../sales_invoice/sales_invoice.actions";
import { soClearUpload } from "../sales_order/sales_order.actions";
import { invoiceClearUpload } from "../invoice/invoice.actions";
import { reClearUpload } from "../receiving/receiving.actions";
import { delClearUpload } from "../delivery/delivery.actions";
import { csClearUpload } from "../customer/customer.actions";

// Handle Dropdown Attachemt File
export const globalDropDown = (e, status) => async (dispatch, getState) => {
  e.preventDefault();
  let { is_attachment } = getState().helper;

  if (status === "show") {
    $(".inv-dropdown").addClass("show");
    $(".arr-link").addClass("hidden");
  } else {
    $(".inv-dropdown").removeClass("show");
    $(".arr-link").removeClass("hidden");
  }
};

// New Attachements
export const isAttachment = (status, origin) => async (dispatch) => {
  if (status === false) {
    if (origin === "close-dropdown") {
      $(".inv-dropdown").removeClass("show");
    }

    // Clearing new uploads in memory
    dispatch(poClearUpload()); // po
    dispatch(quClearUpload()); // qu
    dispatch(siClearUpload()); // si
    dispatch(soClearUpload()); // so
    dispatch(invoiceClearUpload()); // invoice
    dispatch(reClearUpload()); // receiving
    dispatch(delClearUpload()); // delivery
    dispatch(csClearUpload()); // customer
  }

  dispatch({ type: TYPE.NEW_ATTACHMENT, payload: status });
};

export const setDynamicLoading = (key, status) => async (dispatch) => {
  dispatch({
    type: TYPE.SET_DYNAMIC_LOADING,
    payload: { key, status },
  });
};

export const handleHover =
  (status = "in", index) =>
  async (dispatch) => {
    // console.log(index)
    dispatch({
      type: TYPE.HANDLE_HOVER,
      payload: {
        status: false,
        hoverIndex: status == "out" ? null : index,
      },
    });
  };

export const blockRoute = (status) => async (dispatch) => {
  dispatch({ type: TYPE.BLOCK_ROUTE, payload: status });
};

export const clearSupplier = () => async (dispatch) =>
  dispatch({ type: TYPE.CLEAR_SUPPLIER });

// clear the file input value
export const removeFile =
  (name, status, id = null, origin) =>
  async (dispatch) => {
    if (status == "old") {
      SwalWarning(
        "Warning!",
        "Are you sure you want to remove this file?",
        () => {
          switch (origin) {
            case "purchase_order":
              dispatch({
                type: TYPE.PO_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;
            case "quotation":
              dispatch({
                type: TYPE.QUOTATION_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;
            case "sales_invoice":
              dispatch({
                type: TYPE.SI_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;
            case "sales_order":
              dispatch({
                type: TYPE.SO_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;

            case "invoice":
              dispatch({
                type: TYPE.INVOICE_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;
            case "receiving":
              dispatch({
                type: TYPE.RECEIVING_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;
            case "delivery":
              dispatch({
                type: TYPE.DELIVERY_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;
            case "customer":
              dispatch({
                type: TYPE.CUSTOMER_UPLOADS_TO_REMOVE,
                payload: id,
              });
              dispatch(uploadFiles(origin));
              break;
            default:
              return origin;
          }
        }
      );
    } else {
      // New file added
      SwalWarning(
        "Warning!",
        "Are you sure you want to remove this file?",
        () => {
          switch (origin) {
            case "purchase_order":
              dispatch({
                type: TYPE.PO_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;
            case "quotation":
              dispatch({
                type: TYPE.QUOTATION_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;
            case "sales_invoice":
              dispatch({
                type: TYPE.SI_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;
            case "sales_order":
              dispatch({
                type: TYPE.SO_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;
            case "invoice":
              dispatch({
                type: TYPE.INVOICE_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;  
            case "receiving":
              dispatch({
                type: TYPE.RECEIVING_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;
            case "delivery":
              dispatch({
                type: TYPE.DELIVERY_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;
            case "customer":
              dispatch({
                type: TYPE.CUSTOMER_REMOVE_FILE,
                payload: { name: name, status: status },
              });
              break;
            default:
              return origin;
          }
        }
      );
    }
  };

export const setProgress = (payload) => async (dispatch) => {
  dispatch({ type: TYPE.SET_PROGRESS, payload });
};

export const uploadFiles = (origin) => async (dispatch, getState) => {
  try {
    // global unuploadprogress
    const options = {
      onUploadProgress: (progressEvent) => {
        const percent = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        dispatch(setProgress(percent));
      },
    };

    switch (origin) {
      case "purchase_order":
        let { single_purchase_order, new_uploads, files_to_remove } =
          getState().purchase_order;

        let po_form = new FormData();
        po_form.append("origin_id", single_purchase_order._id);

        // new added files
        for (const [_key, _value] of Object.entries(new_uploads)) {
          po_form.append(`new_uploads[]`, _value);
        }

        // ids of files to remove
        if (files_to_remove.length > 0) {
          po_form.append("files_to_remove", JSON.stringify(files_to_remove));
        }

        let po_res = await HelperService.poAttach(po_form, options);

        ToastSuccess(po_res.data.message);
        dispatch({
          type: TYPE.PO_GET_UPLOADED_FILES,
          payload: po_res.data.uploads,
        });
        dispatch(isAttachment(false));
        setTimeout(() => dispatch(setProgress(0)), 10000);
        break;
      case "quotation":
        let {
          single_quotation,
          new_uploads: qu_new_uploads,
          files_to_remove: qu_files_to_remove,
        } = getState().quotation;

        let quForm = new FormData();
        quForm.append("origin_id", single_quotation._id);

        // new added files
        for (const [_key, _value] of Object.entries(qu_new_uploads)) {
          quForm.append(`new_uploads[]`, _value);
        }

        // ids of files to remove
        if (qu_files_to_remove.length > 0) {
          quForm.append("files_to_remove", JSON.stringify(qu_files_to_remove));
        }

        let qu_res = await HelperService.quAttach(quForm, options);

        dispatch({
          type: TYPE.QUOTATION_GET_UPLOADED_FILES,
          payload: qu_res.data.uploads,
        });
        ToastSuccess(qu_res.data.message);
        dispatch(isAttachment(false));
        setTimeout(() => dispatch(setProgress(0)), 10000);
        break;

      case "sales_invoice":
        let {
          single_sales_invoice,
          new_uploads: si_new_uploads,
          files_to_remove: si_files_to_remove,
        } = getState().sales_invoice;

        let siForm = new FormData();
        siForm.append("origin_id", single_sales_invoice._id);

        // new added files
        for (const [_key, _value] of Object.entries(si_new_uploads)) {
          siForm.append(`new_uploads[]`, _value);
        }

        // ids of files to remove
        if (si_files_to_remove.length > 0) {
          siForm.append("files_to_remove", JSON.stringify(si_files_to_remove));
        }

        let si_res = await HelperService.siAttach(siForm, options);

        dispatch({
          type: TYPE.SI_GET_UPLOADED_FILES,
          payload: si_res.data.uploads,
        });
        ToastSuccess(si_res.data.message);
        setTimeout(() => dispatch(setProgress(0)), 10000);
        dispatch(isAttachment(false));
        break;

        case "sales_order":
          let {
            single_sales_order,
            new_uploads: so_new_uploads,
            files_to_remove: so_files_to_remove,
          } = getState().sales_order;
  
          let soForm = new FormData();
          soForm.append("origin_id", single_sales_order._id);
  
          // new added files
          for (const [_key, _value] of Object.entries(so_new_uploads)) {
            soForm.append(`new_uploads[]`, _value);
          }
  
          // ids of files to remove
          if (so_files_to_remove.length > 0) {
            soForm.append("files_to_remove", JSON.stringify(so_files_to_remove));
          }
  
          let so_res = await HelperService.soAttach(soForm, options);
  
          dispatch({
            type: TYPE.SO_GET_UPLOADED_FILES,
            payload: so_res.data.uploads,
          });
          ToastSuccess(so_res.data.message);
          setTimeout(() => dispatch(setProgress(0)), 10000);
          dispatch(isAttachment(false));
          break;

        case "invoice":
        let {
          single_invoice,
          new_uploads: invoice_new_uploads,
          files_to_remove: invoice_files_to_remove,
        } = getState().invoice;
  
        let invoiceForm = new FormData();
        invoiceForm.append("origin_id", single_invoice._id);
  
        // new added files
        for (const [_key, _value] of Object.entries(invoice_new_uploads)) {
          invoiceForm.append(`new_uploads[]`, _value);
        }
  
        // ids of files to remove
        if (invoice_files_to_remove.length > 0) {
          invoiceForm.append("files_to_remove", JSON.stringify(invoice_files_to_remove));
        }
  
        let invoice_res = await HelperService.invoiceAttach(invoiceForm, options);
  
        dispatch({
          type: TYPE.INVOICE_GET_UPLOADED_FILES,
          payload: invoice_res.data.uploads,
        });
        ToastSuccess(invoice_res.data.message);
        setTimeout(() => dispatch(setProgress(0)), 10000);
        dispatch(isAttachment(false));
        break;
  

      case "receiving":
        let {
          receivingData,
          new_uploads: receiving_new_uploads,
          files_to_remove: receiving_files_to_remove,
        } = getState().receiving;

        let reForm = new FormData();
        reForm.append("origin_id", receivingData._id);

        // new added files
        for (const [_key, _value] of Object.entries(receiving_new_uploads)) {
          reForm.append(`new_uploads[]`, _value);
        }

        // ids of files to remove
        if (receiving_files_to_remove.length > 0) {
          reForm.append(
            "files_to_remove",
            JSON.stringify(receiving_files_to_remove)
          );
        }

        let receiving_res = await HelperService.receivingAttach(
          reForm,
          options
        );

        dispatch({
          type: TYPE.RECEIVING_GET_UPLOADED_FILES,
          payload: receiving_res.data.uploads,
        });
        ToastSuccess(receiving_res.data.message);
        setTimeout(() => dispatch(setProgress(0)), 10000);
        dispatch(isAttachment(false));
        break;

      case "delivery":
        let {
          si_data,
          new_uploads: delivery_new_uploads,
          files_to_remove: delivery_files_to_remove,
        } = getState().delivery;

        let delForm = new FormData();
        delForm.append("origin_id", si_data.dr_id);

        // new added files
        for (const [_key, _value] of Object.entries(delivery_new_uploads)) {
          delForm.append(`new_uploads[]`, _value);
        }

        // ids of files to remove
        if (delivery_files_to_remove.length > 0) {
          delForm.append(
            "files_to_remove",
            JSON.stringify(delivery_files_to_remove)
          );
        }

       // console.log("dr_Data", si_data);

        let dr_res = await HelperService.deliveryAttach(delForm, options);

        dispatch({
          type: TYPE.DELIVERY_GET_UPLOADED_FILES,
          payload: dr_res.data.uploads,
        });
        ToastSuccess(dr_res.data.message);
        setTimeout(() => dispatch(setProgress(0)), 10000);
        dispatch(isAttachment(false));
        break;
      case "customer":
        let {
          single_customer,
          new_uploads: cs_new_uploads,
          files_to_remove: cs_files_to_remove,
        } = getState().customer;

        let csForm = new FormData();
        csForm.append("origin_id", single_customer._id);

        // new added files
        for (const [_key, _value] of Object.entries(cs_new_uploads)) {
          csForm.append(`new_uploads[]`, _value);
        }

        // ids of files to remove
        if (cs_files_to_remove.length > 0) {
          csForm.append("files_to_remove", JSON.stringify(cs_files_to_remove));
        }

        let cs_res = await HelperService.csAttach(csForm, options);

        dispatch({
          type: TYPE.CUSTOMER_GET_UPLOADED_FILES,
          payload: cs_res.data.uploads,
        });
        ToastSuccess(cs_res.data.message);
        setTimeout(() => dispatch(setProgress(0)), 10000);
        dispatch(isAttachment(false));
      default:
        return origin;
    }
  } catch (err) {
    ToastDanger("Network error, please reload the page.");
    dispatch(setDynamicLoading("set_loading", false));
    dispatch(setProgress(0));
    console.log(err);
  }
};

// validate only Images
export const validateImage = (files, size = 5) => {
  // console.log(files)
  let validTypes = ["jpg", "jpeg", "png", "bmp", "svg"];

  let status = 0;
  let errors = [];

  if (files.length > 1) {
    errors.push("Please upload single image file only.");
    status = 1;
  }

  files.map((file) => {
    // Check file type
    let ext = file.name.split(".").pop();
    let isType = validTypes.includes(ext);

    if (!isType) {
      errors.push(
        `File type invalid, please upload only ${validTypes
          .join(", ")
          .toUpperCase()}`
      );
      status = 1;
    }

    // Check file size
    let checkSize = file.size / 1024 / 1024;
    if (checkSize.toFixed(2) > size) {
      errors.push(`Max file size is ${size}mb, Please reduce the file size.`);
      status = 1;
    }
  });

  return { status, errors };
};

// validate the file size and types
export const validateFile = (files, filesHave, size = 5) => {
  // console.log(files)
  let validTypes = [
    "jpg",
    "jpeg",
    "png",
    "xlsx",
    "csv",
    "pdf",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "ods",
    "odt",
    "odp",
  ];

  let status = 0;
  let errors = [];

  //console.log("files to have", filesHave);

  files.map((file) => {
    // Check file type
    let ext = file.name.split(".").pop();
    let isType = validTypes.includes(ext);

    if (!isType) {
      errors.push(
        `File type invalid, Please upload only ${validTypes.toString()}.`
      );
      status = 1;
    }

    // Check file size
    let checkSize = file.size / 1024 / 1024;
    if (checkSize.toFixed(2) > size) {
      errors.push(`Max file size is ${size}mb, Please reduce the file size.`);
      status = 1;
    }

    // Check duplicate files
    if (filesHave != null) {
      let filesHaveCheck = filesHave.filter(
        (uploaded) => uploaded.name === file.name.replace(/\s/g, "")
      );
      if (filesHaveCheck.length > 0) {
        errors.push(
          "Duplicate files are not allowed, Please upload another file."
        );
        status = 1;
      }
    }
  });

  return { status, errors };
};

// handle the upload file on update
export const updateFile =
  (fileSelected, origin = null) =>
  async (dispatch, getState) => {
    if (fileSelected) {
      dispatch(isAttachment(true));
    } else {
      dispatch(isAttachment(false));
    }

    switch (origin) {
      case "purchase_order":
        let { uploads: po_uploads } =
          getState().purchase_order.single_purchase_order;

        let poValidateFile = validateFile(fileSelected, po_uploads);
        if (poValidateFile.status === 1) {
          ToastDanger(poValidateFile.errors[0]);
          return false;
        }

        await dispatch({
          type: TYPE.PO_UPDATE_FILE,
          payload: fileSelected,
        });
        dispatch(uploadFiles(origin));
        break;
      case "quotation":
        let { uploads: qu_new_uploads } = getState().quotation.single_quotation;

        let quValidateFile = validateFile(fileSelected, qu_new_uploads);
        if (quValidateFile.status === 1) {
          ToastDanger(quValidateFile.errors[0]);
          return false;
        }

        dispatch({
          type: TYPE.QUOTATION_UPDATE_FILE,
          payload: fileSelected,
        });
        dispatch(uploadFiles(origin));
        break;
      case "sales_invoice":
        let { uploads: si_new_uploads } =
          getState().sales_invoice.single_sales_invoice;

        let siValidateFile = validateFile(fileSelected, si_new_uploads);
        if (siValidateFile.status === 1) {
          ToastDanger(siValidateFile.errors[0]);
          return false;
        }

        dispatch({
          type: TYPE.SI_UPDATE_FILE,
          payload: fileSelected,
        });
        dispatch(uploadFiles(origin));
        break;
      case "sales_order":
        let { uploads: so_new_uploads } =
          getState().sales_order.single_sales_order;

        let soValidateFile = validateFile(fileSelected, so_new_uploads);
        if (soValidateFile.status === 1) {
          ToastDanger(soValidateFile.errors[0]);
          return false;
        }

        dispatch({
          type: TYPE.SO_UPDATE_FILE,
          payload: fileSelected,
        });
        dispatch(uploadFiles(origin));
        break;

        case "invoice":
          let { uploads: invoice_new_uploads } =
            getState().invoice.single_invoice;
  
          let invoiceValidateFile = validateFile(fileSelected, invoice_new_uploads);
          if (invoiceValidateFile.status === 1) {
            ToastDanger(invoiceValidateFile.errors[0]);
            return false;
          }
  
          dispatch({
            type: TYPE.INVOICE_UPDATE_FILE,
            payload: fileSelected,
          });
          dispatch(uploadFiles(origin));
          break;

      case "receiving":
        let { uploads: re_new_uploads } = getState().receiving.receivingData;

        let recValidateFile = validateFile(fileSelected, re_new_uploads);
        if (recValidateFile.status === 1) {
          ToastDanger(recValidateFile.errors[0]);
          return false;
        }

        dispatch({
          type: TYPE.RECEIVING_UPDATE_FILE,
          payload: fileSelected,
        });
        dispatch(uploadFiles(origin));
        break;
      case "delivery":
        let { uploads: dr_new_uploads } = getState().delivery.si_data;

        let drValidateFile = validateFile(fileSelected, dr_new_uploads);
        if (drValidateFile.status === 1) {
          ToastDanger(drValidateFile.errors[0]);
          return false;
        }

        dispatch({
          type: TYPE.DELIVERY_UPDATE_FILE,
          payload: fileSelected,
        });
        dispatch(uploadFiles(origin));
        break;
      case "customer":
        let { uploads: cs_new_uploads } = getState().customer.single_customer;

        let csValidateFile = validateFile(fileSelected, cs_new_uploads);
        if (csValidateFile.status === 1) {
          ToastDanger(csValidateFile.errors[0]);
          return false;
        }

        dispatch({
          type: TYPE.CUSTOMER_UPDATE_FILE,
          payload: fileSelected,
        });
        dispatch(uploadFiles(origin));

      default:
        return origin;
    }
  };

// Dynamic MUIDataTable Setting per user_id  ex.(rows, columns, view-columns)
export const updateMUISetting =
  (type, table, num_rows = null, columns = null) =>
  (async) => {
    let { _id: user_id } = getUserData();
    let muisetting = getMUIDataSetting();

    // console.log(table, num_rows);
    const checkUser = muisetting
      ? muisetting.filter((mui) => mui.user_id === user_id)
      : null;

    switch (type) {
      case "rows":
        if (muisetting !== null) {
          if (checkUser.length > 0) {
            // update current user setting
            muisetting.map((mui, i) => {
              if (mui.user_id === user_id) {
                // check if table exists
                let checkTableExist = mui.tables.some(
                  (tbl) => tbl.table === table
                );
                if (checkTableExist) {
                  // update num_rows
                  mui.tables.map((_tbl) => {
                    if (_tbl.table === table) {
                      _tbl.num_rows = num_rows;
                    }
                  });
                } else {
                  // will add new table per userid if new table exists
                  mui.tables.push({ table, num_rows });
                }
              }
            });
          } else {
            // insert new user setting
            muisetting.push({
              user_id,
              tables: [{ table, num_rows }],
            });
          }

          // store
          localStorage.setItem("muisetting", JSON.stringify(muisetting));
        } else {
          // if empty localstorage insert user setting
          let newMUIRows = [{ user_id, tables: [{ table, num_rows }] }];
          localStorage.setItem("muisetting", JSON.stringify(newMUIRows));
        }

        break;

      case "columns":
        // console.log('COLUMNS')
        let saveColumn = columns.map((col, i) => {
          let new_col = { name: col.name, display: col.display };
          return (col[i] = new_col);
        });

        if (muisetting !== null) {
          // console.log(table, columns, saveColumn)
          if (checkUser.length > 0) {
            muisetting.map((mui) => {
              if (mui.user_id === user_id) {
                // check if table exists
                let checkTableExist = mui.tables.some(
                  (tbl) => tbl.table === table
                );
                if (checkTableExist) {
                  // update num_rows
                  mui.tables.map((_table) => {
                    if (_table.table === table) {
                      _table["columns"] = saveColumn;
                    }
                  });
                } else {
                  // will add new table per userid if new table exists
                  mui.tables.push({ table, columns: saveColumn });
                }
              }
            });
          } else {
            // without user setting in localstorage
            muisetting.push({
              user_id,
              tables: [{ table, columns: saveColumn }],
            });
          }

          // store to localstorage
          localStorage.setItem("muisetting", JSON.stringify(muisetting));
        } else {
          // if empty localstorage insert user setting
          let newMUIColumns = [
            { user_id, tables: [{ table, columns: saveColumn }] },
          ];
          localStorage.setItem("muisetting", JSON.stringify(newMUIColumns));
        }

        break;
      case "view-columns":
        // console.log(table, columns)
        if (muisetting !== null) {
          muisetting.map((mui) => {
            // check user_id
            if (mui.user_id === user_id) {
              // check if table exists
              let checkTableExist = mui.tables.some(
                (tbl) => tbl.table === table
              );
              if (checkTableExist) {
                // update num_rows
                mui.tables.map((table) => {
                  if (table.columns) {
                    // update column display true || false
                    columns.map((col, i) => {
                      table.columns.map((_col, i) => {
                        if (col.name === _col.name) {
                          col["options"]["display"] = _col.display;
                        }
                      });
                    });
                  }
                });
              }
            }
          });
        } else {
          let newColumn = columns.map((col, i) => {
            let new_col = {
              name: col.name,
              display: col.options.display,
            };
            return (col[i] = new_col);
          });

          let newMUIColumns = [
            { user_id, tables: [{ table, columns: newColumn }] },
          ];
          localStorage.setItem("muisetting", JSON.stringify(newMUIColumns));
        }

        return columns;
        break;
      default:
        // nothing here
        break;
    }
  };

// dynamic page use for redirect to current page after updating data
export const setMUIPage = (page) => async (dispatch) => {
  dispatch({ type: TYPE.SET_MUI_PAGE, payload: page });
};
