import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { downloadTemplate, importProduct} from '../../../redux/actions/product/product.actions';
import {useDropzone} from 'react-dropzone';
import { fileExtensionIcon } from '../../../Utils/Common';
import { removeFile, handleHover, uploadFiles, updateFile, globalDropDown, setProgress } from '../../../redux/actions/helper/helper.actions';
import { MiniSpinner } from '../../layouts/Spinner';
import {  ProgressBar } from 'react-bootstrap';

function ImportModal({ show, onHide, import_product, downloadTemplate, imported_products, finish_import, downloading_template, progress, importProduct}) {

    const [showProgress, setShowProgress] = useState(0);
    useEffect(() => {
        if(progress > 0){
            setShowProgress(progress);
        }
    },[progress]);
    

    const onDrop = useCallback(acceptedFiles => {
        importProduct(acceptedFiles);
    }, [])
    const {getRootProps, getInputProps, isDragActive } = useDropzone(
        {
            onDrop, 
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            disabled : import_product
        }
    );

    // console.log("imported_products", imported_products)
    return (
        <Modal centered dialogClassName="modal-container" show={show} onHide={onHide} size="md" animation={true}>
            <Modal.Header closeButton> <Modal.Title>Import Products</Modal.Title> </Modal.Header>
            <Modal.Body>
                <div className="row mb-1m">
                    <div className="col-md-12 text-center">
                        {
                            downloading_template ? 
                            <button className="btn btn-blue"><i className="icon-loading-custom icon-15"></i></button> :
                            <button onClick={downloadTemplate} className="btn btn-blue"><i className="ft ft-download"></i> Download Template</button>
                        }
                    </div>
                </div>
                <div className="row mb-1m">
                    <div className="col-md-12 text-center">
                         <div className="col-12 my-2 px-0">
                            <div {...getRootProps()} className={(import_product ? "op-25" : "") + " file-dropzone-line-print-modal"}>
                                <input {...getInputProps()}/>
                                <div className="file-upload-message-print-modal">Upload Product Template here...</div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    import_product ? 
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <MiniSpinner/>
                        </div>
                    </div>
                    :
                    finish_import ?
                    <div className="row">
                        <div className="col-md-12 mb-1m ">
                            <div className=" alert alert-success">
                                {imported_products.success} new product(s) has been imported.
                            </div>
                        </div>
                        {
                            imported_products.duplicate.length > 0 ?
                            <div className="col-md-12  mb-1m ">
                                <div className="alert alert-danger">
                                    <label>Duplicate Entries ({imported_products.duplicate.length})</label>
                                    <ul>
                                        {
                                            imported_products.duplicate.map((item, index) => {
                                                return(
                                                    <li key={index}> {item.product_name} ( { 'SKU : '+item.sku + ', Barcode :' + item.barcode} )</li>
                                                )
                                                
                                            })
                                        }
                                    
                                    </ul>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    :
                    <></>
                }
                
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => ({
	product: state.product,
    progress: state.helper.progress,
    import_product: state.product.import_product,
    downloading_template : state.product.downloading_template,
    imported_products : state.product.imported_products,
    finish_import : state.product.finish_import
});

export default connect(mapStateToProps, {downloadTemplate, importProduct})(ImportModal);
