import * as TYPES from '../types';

// state
const initialState = {
    pagination: { totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    search: '',
    stock_logs: [],
    single_stock_logs: {},
    sort_order_name: null,
    sort_order_direction: null,
    loading: false,
    lotno_modal: false
}

const stockLogsReducer = (state = initialState, action) => {
   switch (action.type) {
       case TYPES.GET_STOCK_LOGS_DATA:
           return {
               ...state,
                stock_logs: action.payload.stock_logs,
                pagination: action.payload.pagination,
           }
        case TYPES.SEARCH_INPUT:
            return {
                ...state,
                search: action.payload
            }
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }
        case TYPES.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status
            }
       default:
           return state;
           break;
   }

}

export default stockLogsReducer;

