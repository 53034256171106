import {
    SET_LOADING,
    GET_PRODUCTS,
    GET_SELECT_ALL,
    SEARCH_INPUT,
    SET_PAGE,
    CLEAR_PRODUCT,
    PRODUCT_HANDLE_SELECT,
    PRODUCT_ATTR_HANDLE_SELECT,
    HANDLE_UPDATE_ATTR_LIST,
    HANDLE_ATTR_CHECKBOX,
    PRODUCT_INPUT_CHANGE,
    GET_SINGLE_PRODUCT,
    RESET_ATTRIBUTE_LIST_VALUE,
    SET_MINI_LOADING,
    HANDLE_ITEM_INPUT_UPLOAD,
    HANDLE_ITEM_REMOVE_UPLOAD,
    HANDLE_ITEM_FORM_DISABLE,
    HANDLE_ITEM_SELECT_SUPPLIER,
    HANDLE_SUPPLIER_COST,
    HANDLE_ADD_VOLUME_PRICING,
    HANDLE_REMOVE_VOLUME_PRICING,
    HANDLE_INPUT_VOLUME_PRICING,
    HANDLE_SELECT_DISCOUNT_TYPE_VOLUME_PRICING,
    HANDLE_ON_CHANGE_ITEM_TAGS,
    HANDLE_REMOVE_ITEM_TAG,
    UPDATE_ITEM_TAGS,
    SET_MODAL,
    COPY_SKU_TO_BARCODE,
    EDIT_BARCODE,
    SORT_BY_COLUMN,
    GET_ROWS_TO_DELETE,
    IS_SALE_PRICE,
    IS_TRACK,
    SET_LOADING_TAG,
    IMPORT_MODAL_PRODUCT,
    IMPORT_PRODUCT,
    DOWNLOADING_TEMPLATE,
    IMPORTED_PRODUCT,
    FINISH_IMPORT,
    STOCK_LOGS,
    SET_ROW_CHECKED,
    UPDATE_SUPPLIER,
    EDIT_COST
} from '../types';

// state
const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    products: [],
    single_product: {
        _id: '',
        sku: '',
        product_name: '',
        description: '',
        barcode: '',
        brand_id: '',
        category_id: '',
        supplier_id: '',
        attribute_template_id: '',
        product_unit_id: '',
        cost: 0,
        srp: 0,
        delivery_fee: 0,
        customization_fee: 0,
        low_threshold: 0,
        initial_stock: 0,
        sale_price: 0,
        attribute_lists: [],
        item_tags: [],
        brand_id_default: { value: '', label: 'Choose Brand' },
        category_id_default: { value: '', label: 'Choose Category' },
        supplier_id_default: [],
        attribute_template_id_default: {
            value: '',
            label: 'Choose Attributes Template',
        },
        product_unit_id_default: { value: '', label: 'Choose Unit' },
        brand: {},
        category: {},
        supplier: {},
        attribute_template: {},
        product_unit: {},
        attribute_string: null,
        upload: '',
        upload_preview: '',
        volume_price: [
            {
                qty: 0,
                discount_type: { value: 'fix', label: 'Fix Price' },
                discount: 0,
            },
        ],
        is_sale_price: false,
        is_track: true,
        mute_stock: false,
        mute_threshold: false,
    },
    categories: [],
    brands: [],
    suppliers: [],
    attribute_templates: [],
    product_units: [],
    item_tags_options: [],
    _item_tags_options: [],
    removable_tags: [],
    categories_options: [],
    brands_options: [],
    suppliers_options: [],
    attribute_templates_options: [],
    product_units: [],
    discount_type_options: [
        { value: 'fix', label: 'Fix Price' },
        { value: 'percent', label: 'By Percent' },
    ],
    search: '',
    loading: false,
    mini_loading: false,
    isSearchable: true,
    form_disable: false,
    main_page: false,
    edit_page: false,
    delete_modal: false,
    editable_cost: false,
    main_url: '/inventory/products?mode=Items',
    // create_url: '/products/items/create',
    // edit_url: '/products/items/edit',
    edit_url: '/inventory/products/items/edit',
    create_url: '/inventory/products/items/create',
    export_url:
        process.env.REACT_APP_API_LINK + '/admin/product/export/product/list',
    tag_modal: false,
    brand_modal: false,
    category_modal: false,
    unit_modal: false,
    supplier_modal: false,
    attribute_modal: false,
    barcode_disable: false,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    loading_tag: false,
    import_modal: false,
    import_product: false,
    downloading_template: false,
    imported_products: {
        duplicate: [],
        success: 0,
    },
    finish_import: false,
    set_row_checked: false,
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload,
            };
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case GET_SINGLE_PRODUCT:
            return {
                ...state,
                single_product: { ...state.single_product, ...action.payload },
            };
        case GET_SELECT_ALL:
            return {
                ...state,
                categories: action.payload.categories,
                brands: action.payload.brands,
                suppliers: action.payload.suppliers,
                attribute_templates: action.payload.attribute_templates,
                product_units: action.payload.product_units,
                item_tags_options: action.payload.tags,
                _item_tags_options: action.payload.tags,
            };
        case PRODUCT_INPUT_CHANGE:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    [action.payload.key]: action.payload.value,
                },
            };
        case PRODUCT_HANDLE_SELECT:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    [action.payload.key]: action.payload.value,
                    [action.payload.default_key]: action.payload.default_value,
                },
            };
        case PRODUCT_ATTR_HANDLE_SELECT:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    [action.payload.key]: action.payload.value,
                    [action.payload.default_key]: action.payload.default_value,
                    attribute_lists: action.payload.attribute_lists,
                },
            };
        case HANDLE_UPDATE_ATTR_LIST:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    attribute_lists: action.payload,
                },
            };
        case HANDLE_ATTR_CHECKBOX:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    attribute_lists: action.payload,
                },
            };
        case HANDLE_ITEM_INPUT_UPLOAD:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    upload: action.payload.upload,
                    upload_preview: action.payload.upload_preview,
                },
            };
        case HANDLE_ITEM_REMOVE_UPLOAD:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    upload: '',
                    upload_preview: '',
                },
            };
        case SET_PAGE:
            if (action.payload.page == 'main_page') {
                return {
                    ...state,
                    edit_page: false,
                    [action.payload.page]: action.payload.status,
                };
            } else if (action.payload.page == 'edit_page') {
                return {
                    ...state,
                    main_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status,
                };
            } else {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                };
            }
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case RESET_ATTRIBUTE_LIST_VALUE:
            return {
                ...state,
                attribute_templates: action.payload,
            };
        case HANDLE_ITEM_FORM_DISABLE:
            return {
                ...state,
                form_disable: action.payload,
            };
        case HANDLE_ITEM_SELECT_SUPPLIER:
            if (action.payload == null) {
                return {
                    ...state,
                    single_product: {
                        ...state.single_product,
                        supplier_id: '',
                        supplier_id_default: [],
                    },
                };
            } else {
                return {
                    ...state,
                    single_product: {
                        ...state.single_product,
                        supplier_id: action.payload,
                        supplier_id_default: action.payload,
                    },
                };
            }
        case HANDLE_SUPPLIER_COST:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    single_id_default: [...action.payload],
                },
            };
        case HANDLE_ADD_VOLUME_PRICING:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    volume_price: action.payload,
                },
            };
        case HANDLE_REMOVE_VOLUME_PRICING:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    volume_price: action.payload,
                },
            };
        case HANDLE_INPUT_VOLUME_PRICING:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    volume_price: action.payload,
                },
            };
        case HANDLE_SELECT_DISCOUNT_TYPE_VOLUME_PRICING:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    volume_price: action.payload,
                },
            };
        case HANDLE_ON_CHANGE_ITEM_TAGS:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    item_tags: action.payload,
                },
            };
        case HANDLE_REMOVE_ITEM_TAG:
            return {
                ...state,
                _item_tags_options: action.payload._item_tags_options,
                removable_tags: action.payload.removable_tags,
            };
        case UPDATE_ITEM_TAGS:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    item_tags: action.payload.item_tags_update,
                },
                item_tags_options: action.payload.item_tags_options,
                _item_tags_options: action.payload.item_tags_options,
            };
        case SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case COPY_SKU_TO_BARCODE:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    barcode: action.payload,
                },
                barcode_disable: true,
            };
        case EDIT_BARCODE:
            return {
                ...state,
                barcode_disable: false,
            };
        case CLEAR_PRODUCT:
            return {
                ...state,
                pagination: {
                    totalCount: 0,
                    activePage: 1,
                    itemsCountPerPage: 1,
                    totalItemsCount: 10,
                    pageRangeDisplayed: 8,
                },
                products: [],
                single_product: {
                    _id: '',
                    sku: '',
                    product_name: '',
                    description: '',
                    barcode: '',
                    brand_id: '',
                    category_id: '',
                    supplier_id: '',
                    attribute_template_id: '',
                    product_unit_id: '',
                    cost: 0,
                    srp: 0,
                    delivery_fee: 0,
                    customization_fee: 0,
                    low_threshold: 0,
                    initial_stock: 0,
                    sale_price: 0,
                    attribute_lists: [],
                    item_tags: [],
                    brand_id_default: { value: '', label: 'Choose Brand' },
                    category_id_default: {
                        value: '',
                        label: 'Choose Category',
                    },
                    supplier_id_default: [],
                    attribute_template_id_default: {
                        value: '',
                        label: 'Choose Attributes Template',
                    },
                    product_unit_id_default: {
                        value: '',
                        label: 'Choose Unit',
                    },
                    brand: {},
                    category: {},
                    supplier: {},
                    attribute_template: {},
                    product_unit: {},
                    attribute_string: null,
                    upload: '',
                    upload_preview: '',
                    volume_price: [
                        {
                            qty: 0,
                            discount_type: { value: 'fix', label: 'Fix Price' },
                            discount: 0,
                        },
                    ],
                    is_sale_price: false,
                    is_track: true,
                    mute_stock: false,
                    mute_threshold: false,
                },
                categories: [],
                brands: [],
                suppliers: [],
                attribute_templates: [],
                product_units: [],
                item_tags_options: [],
                _item_tags_options: [],
                removable_tags: [],
                categories_options: [],
                brands_options: [],
                suppliers_options: [],
                attribute_templates_options: [],
                product_units: [],
                discount_type_options: [
                    { value: 'fix', label: 'Fix Price' },
                    { value: 'percent', label: 'By Percent' },
                ],
                search: '',
                loading: false,
                mini_loading: false,
                isSearchable: true,
                form_disable: false,
                main_page: false,
                edit_page: false,
                delete_modal: false,
                main_url: '/inventory/products?mode=Items',
                // create_url: '/products/items/create',
                // edit_url: '/products/items/edit',
                edit_url: '/inventory/products/items/edit',
                create_url: '/inventory/products/items/create',
                export_url:
                    process.env.REACT_APP_API_LINK +
                    '/admin/product/export/product/list',
                tag_modal: false,
                brand_modal: false,
                category_modal: false,
                unit_modal: false,
                supplier_modal: false,
                attribute_modal: false,
                barcode_disable: false,
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                loading_tag: false,
            };
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case IS_SALE_PRICE:
            return {
                ...state,
                single_product: { ...action.payload },
            };
        case IS_TRACK:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    is_track: action.payload,
                },
            };
        case SET_LOADING_TAG:
            return {
                ...state,
                loading_tag: action.payload,
            };
        case IMPORT_MODAL_PRODUCT:
            return {
                ...state,
                import_modal: action.payload,
            };
        case IMPORT_PRODUCT:
            return {
                ...state,
                import_product: action.payload,
            };
        case DOWNLOADING_TEMPLATE:
            return {
                ...state,
                downloading_template: action.payload,
            };
        case IMPORTED_PRODUCT:
            return {
                ...state,
                imported_products: action.payload,
            };
        case FINISH_IMPORT:
            return {
                ...state,
                finish_import: action.payload,
            };
        case SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case UPDATE_SUPPLIER:
            return {
                ...state,
                single_product: {
                    ...state.single_product,
                    supplier_id: action.payload.supplier_id,
                    supplier_id_default: action.payload.supplier_id_default,
                },
            };
        case EDIT_COST:
            return {
                ...state,
                editable_cost: action.payload,
            };
        default:
            return state;
    }
};

export default productReducer;
