import React, { Fragment, useEffect } from 'react';
import Select, { components } from 'react-select';
import { NumberFormat } from '../../Services/_numberformat.service.js';
import { MiniSpinner } from '../layouts/Spinner.js';
import AsyncSelect from 'react-select/async';
import {
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    handleSelectSalesOrderTemplate,
    handleSelectReceiptType,
    getOrderTemplates,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    getTermsConditions,
    getCustomers,
    getProducts,
    setPage,
    setModal,
    SIDatePicker,
    getSingleSalesOrder,
    handleSelectPaymentType,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    add_items,
    dynamicInputChange,
    searchProducst,
    handleTaxType,
    computationHandler,
    ShowComboBox,
    cancelSi,
    handleSelectQuotation,
    getQuotations,
} from '../../redux/actions/sales_order/sales_order.actions.js';
import { connect, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import SalesOrderViewTable from './SalesOrder.view.table';
import ReactTooltip from 'react-tooltip';
import SalesOrderCustomerModal from './modals/SalesOrder.customer.modal.js';
import DatePicker from 'react-datepicker';
import PaymentTermsModal from './modals/PaymentTerms.modal.js';
import { Show, useAccess } from 'react-redux-permission';
import { SwalFormWarning } from '../../Services/_swal.service.js';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions.js';
import debounce from 'debounce-promise';
import DynamicFont from 'react-dynamic-font';
import { stockClass } from '../../Utils/Common.js';

function SalesOrderEdit({
    sales_order: {
        single_sales_order,
        mini_loading,
        customers,
        terms_conditions,
        isSearchable,
        itemSelected,
        items,
        isDiscount,
        default_product_value,
        isOther,
        sales_order_templates,
        receipt_type_options,
        si_default_template,
        default_select_type_order,
        isQuotationNo,
        edit_sales_order_items,
        edit_row_index,
        edit_attr,
        default_customer_value,
        main_url,
        main_page,
        payment_type_options,
        customer_modal,
        payment_terms_modal,
        loading,
        tax_types,
        btn_form,
        quotation_options,
    },
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    setModal,
    handleCheckVat,
    handleInputQty,
    handleSelectSalesOrderTemplate,
    handleSelectReceiptType,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    getOrderTemplates,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    getSingleSalesOrder,
    handleSelectPaymentType,
    handleAttrSubmit,
    getTermsConditions,
    getCustomers,
    getProducts,
    setPage,
    match,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleCheckManual,
    SIDatePicker,
    add_items,
    dynamicInputChange,
    block_route,
    blockRoute,
    searchProducst,
    handleTaxType,
    computationHandler,
    ShowComboBox,
    cancelSi,
    handleSelectQuotation,
    getQuotations,
}) {
    const history = useHistory();

    useEffect(() => {
        async function fetchAll() {
            Promise.all([
                getOrderTemplates(),
                getTermsConditions(),
                getCustomers(),
                getProducts(),
                getQuotations(),
                getSingleSalesOrder(match.params.id),
                blockRoute(false),
            ]);
        }

        fetchAll();
    }, []);

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
            console.log('01');
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
            // console.log('02');
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const {
        sales_order_items,
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    } = single_sales_order;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(sales_order_items),
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    ]);

    const current_sales_order_id = useSelector(
        (state) => state.sales_order.single_sales_order._id
    );

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () => {
        window.history.pushState(null, null, window.location.pathname);
        console.log('03');
    };

    if (main_page) return <Redirect to={main_url} />;
    const customFilter = (option, searchText) => {
        if (
            option.data.label
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
            option.data.barcode.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };

    // console.log("single_sales_order", single_sales_order);
    // computed subtotal
    // const subTotalComputed = single_sales_order.sales_order_items.reduce((sub, item) => sub + parseFloat(item.amount ? item.amount : item.srp), 0);
    // const discount = single_sales_order.discount_type == 1 ? ((parseFloat(subTotalComputed) * single_sales_order.discount) / 100) : single_sales_order.discount;
    // const subTotalDiscounted = subTotalComputed - discount;
    // const totalPriceComputed = subTotalDiscounted + ((parseFloat(subTotalDiscounted) * single_sales_order.vat) / 100) + single_sales_order.other;
    // // formatted price
    // const subTotal = NumberFormat(subTotalComputed.toFixed(2));
    // const total = NumberFormat(totalPriceComputed.toFixed(2));
    // const itemSelectedAmount = itemSelected.qty * itemSelected.srp;

    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    const Option = (props) => {
        // console.log(props)
        return (
            <components.Option {...props}>
                <div>{props.data.label} [{props.data.brand}]</div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
                {/* <div style={{ fontSize: 11 }}>{props.data.lot_no}</div>
                <div style={{ fontSize: 11 }}>{props.data.brand}</div> */}
            </components.Option>
        );
    };
    const status_lbl = (
        <span className={`lbl-status mr-1 lbl-${single_sales_order.status}`}>
            {single_sales_order.status}
        </span>
    );
    // const payment_mode = single_sales_order.payment_type && single_sales_order.payment_type.value != '' ? <span className={`lbl-status mr-1 lbl-payment-type lbl-${single_sales_order.payment_type.label}`}>{single_sales_order.payment_type.label}</span> : '';
    // const payment = single_sales_order.payment_status !== '' && single_sales_order.payment_status !== undefined ? <span className={`lbl-status mr-1 payment_status lbl-${single_sales_order.payment_status}`}>{single_sales_order.payment_status}</span> : '';
    // const delivery = single_sales_order.delivery_status !== '' && single_sales_order.delivery_status !== undefined ? <span className={`lbl-status mr-1 delivery_status lbl-${single_sales_order.delivery_status}`}>{single_sales_order.delivery_status}</span> : '';

    //  console.log('EDITC', single_sales_order);

    return (
        <BeforeUnloadComponent
            ignoreBeforeunloadDocument={true}
            blockRoute={block_route}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Sales
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link
                                                to={main_url}
                                                className="text-white"
                                            >
                                                Sales Order No.
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#" className="text-white">
                                                {''}
                                            </a>
                                            <a href="#" className="text-white">
                                                {
                                                    single_sales_order.sales_order_no
                                                }
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header mb-0 pb-0">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left"></i>{' '}
                                                Back
                                            </Link>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body pb-10m f-regular">
                                                {mini_loading ? (
                                                    <MiniSpinner />
                                                ) : single_sales_order.status !=
                                                      'saved' &&
                                                  single_sales_order.status !=
                                                      'delivered' ? (
                                                    <form>
                                                        <ReactTooltip
                                                            effect="solid"
                                                            event="click"
                                                        />
                                                        <section>
                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    {status_lbl}
                                                                    {/* {payment} */}
                                                                    {/* {delivery} */}
                                                                    {/* {payment_mode} */}
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        {single_sales_order.is_manual ? (
                                                                            <Fragment>
                                                                                <div className="col-sm-8"></div>
                                                                                <div className="col-sm-4">
                                                                                    <label className="mb-2">
                                                                                        <i className="ft ft-check-square"></i>{' '}
                                                                                        Manually
                                                                                        Input
                                                                                        Reference
                                                                                        No.
                                                                                    </label>
                                                                                </div>
                                                                            </Fragment>
                                                                        ) : null}
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Template{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            sales_order_templates
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectSalesOrderTemplate
                                                                                        }
                                                                                        value={
                                                                                            si_default_template
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4"></div>
                                                                        <div className="col-sm-4">
                                                                            {single_sales_order.is_manual ? (
                                                                                <section>
                                                                                    <label>
                                                                                        Reference
                                                                                        No.{' '}
                                                                                        <span className="text-danger">
                                                                                            *
                                                                                        </span>
                                                                                    </label>
                                                                                    <div className="form-group row">
                                                                                        <div className="col-md-10 col-10 pr-0">
                                                                                            {/* <div className='form-control f-regular'>
                                            {single_sales_order.reference_no}
                                          </div> */}
                                                                                            <input
                                                                                                onChange={
                                                                                                    handleInputChange
                                                                                                }
                                                                                                type="text"
                                                                                                name="reference_no"
                                                                                                value={
                                                                                                    single_sales_order.reference_no
                                                                                                }
                                                                                                className="form-control f-regular"
                                                                                                placeholder="Reference No."
                                                                                            />
                                                                                        </div>
                                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                                            <div className="input-group-prepend">
                                                                                                <span
                                                                                                    href="#"
                                                                                                    className="tool-tip text-primary"
                                                                                                    data-tip="this is a tool tip"
                                                                                                >
                                                                                                    <i className="la la-question-circle"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </section>
                                                                            ) : (
                                                                                <section>
                                                                                    <label>
                                                                                        Quotation
                                                                                        No.
                                                                                    </label>
                                                                                    <div className="form-group row">
                                                                                        <div className="col-md-10 col-10 pr-0">
                                                                                            <Select
                                                                                                // value={{ value: single_sales_order.quotation_no, label: single_sales_order.quotation_no }}
                                                                                                defaultValue={{
                                                                                                    value: single_sales_order.reference_no,
                                                                                                    label: single_sales_order.reference_no,
                                                                                                }}
                                                                                                onChange={(
                                                                                                    option
                                                                                                ) =>
                                                                                                    handleSelectQuotation(
                                                                                                        option,
                                                                                                        current_sales_order_id
                                                                                                    )
                                                                                                }
                                                                                                options={
                                                                                                    quotation_options
                                                                                                }
                                                                                                isSearchable={
                                                                                                    isSearchable
                                                                                                }
                                                                                                required
                                                                                            />
                                                                                        </div>
                                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                                            <div className="input-group-prepend">
                                                                                                <span
                                                                                                    href="#"
                                                                                                    className="tool-tip text-primary"
                                                                                                    data-tip="this is a tool tip"
                                                                                                >
                                                                                                    <i className="la la-question-circle"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Customer
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    {/* <Select options={customers} value={default_customer_value} isSearchable={isSearchable} onChange={handleSelectCustomer} placeholder="Select Customer..." required/> */}
                                                                                    <div className="form-control f-regular">
                                                                                        {
                                                                                            default_customer_value.label
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                        <Show when="inv.sales.customers.create">
                                                                                            <div
                                                                                                className="a-tagicon icon-s"
                                                                                                onClick={() =>
                                                                                                    setModal(
                                                                                                        'customer_modal',
                                                                                                        true
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                                                            </div>
                                                                                        </Show>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4"></div>
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Order
                                                                                Date
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <div className="date-picker-100">
                                                                                        <DatePicker
                                                                                            onChange={(
                                                                                                date
                                                                                            ) =>
                                                                                                SIDatePicker(
                                                                                                    date
                                                                                                )
                                                                                            }
                                                                                            selected={
                                                                                                new Date(
                                                                                                    single_sales_order.sales_order_date
                                                                                                )
                                                                                            }
                                                                                            name="sales_order_date"
                                                                                            className="form-control f-regular"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {single_sales_order.customer && (
                                                                        <Fragment>
                                                                            <table className="table table-hover table-striped table-bordered f-regular">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-left td-16m">
                                                                                            SKU
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Product
                                                                                        </th>
                                                                                        <th className="text-center mw-6m">
                                                                                            Qty
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Unit
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            SRP
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Unit/Price
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Amount
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Action
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {single_sales_order.sales_order_items.map(
                                                                                        (
                                                                                            item,
                                                                                            index
                                                                                        ) => (
                                                                                            <Fragment
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {' '}
                                                                                                {/* This is the key part */}
                                                                                                <tr
                                                                                                    className={stockClass(
                                                                                                        item.status_code
                                                                                                    )}
                                                                                                >
                                                                                                    {/* <tr
                                                key={index}
                                                className={stockClass(
                                                  item.status_code
                                                )}
                                              > */}
                                                                                                    <td className="table-data__sku ">
                                                                                                        {
                                                                                                            item.sku
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="min-wdth-300 max-wdth-300">
                                                                                                        {
                                                                                                            item.new ? (
                                                                                                                item.selected ? (
                                                                                                                    <div>
                                                                                                                         {/* <div className='f-right' onClick={() => ShowComboBox(index)}>
                                                                                                                            <i className='ft ft-chevron-down c-pointer'></i>
                                                                                                                        </div> */}
                                                                                                                        <div className='mw-90'>
                                                                                                                            <Link to={`/inventory/view/${item._id}`} 
                                                                                                                                target="_blank" 
                                                                                                                                onClick={(e) => e.stopPropagation()}
                                                                                                                                >
                                                                                                                                {item.product_name}
                                                                                                                            </Link>
                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.barcode
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.description
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.brand
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <AsyncSelect
                                                                                                                        value={{
                                                                                                                            value: item._id,
                                                                                                                            label: item.product_name,
                                                                                                                        }}
                                                                                                                        components={{
                                                                                                                            Option,
                                                                                                                        }}
                                                                                                                        isLoading={
                                                                                                                            loading
                                                                                                                        }
                                                                                                                        loadingMessage={() =>
                                                                                                                            'searching...'
                                                                                                                        }
                                                                                                                        noOptionsMessage={({ inputValue }) =>
                                                                                                                        inputValue ? 'No Products' : 'Search product'
                                                                                                                        }
                                                                                                                        defaultOptions={
                                                                                                                            item.items
                                                                                                                        }
                                                                                                                        loadOptions={debounce(
                                                                                                                            (
                                                                                                                                value
                                                                                                                            ) =>
                                                                                                                                searchProducst(
                                                                                                                                    value,
                                                                                                                                    index
                                                                                                                                ),
                                                                                                                            1000
                                                                                                                        )}
                                                                                                                        isSearchable={
                                                                                                                            true
                                                                                                                        }
                                                                                                                        cacheOptions
                                                                                                                        onChange={(
                                                                                                                            val
                                                                                                                        ) =>
                                                                                                                            handleSelectItem(
                                                                                                                                val,
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }
                                                                                                                        placeholder="Select Products..."
                                                                                                                        required
                                                                                                                    />
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <div>
                                                                                                                   <div className='mw-90'>
                                                                                                                        <Link to={`/inventory/view/${item.product_id}`} 
                                                                                                                            target="_blank" 
                                                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                                                            >
                                                                                                                            {item.product_name}
                                                                                                                        </Link>
                                                                                                                    </div>

                                                                                                                    <>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.barcode
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.description
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="f-12 text-dark">
                                                                                                                            {
                                                                                                                                item.brand
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                </div>
                                                                                                            )
                                                                                                            //  <span>{item.product_name} <br/> <small className="text-secondary">{item.products != null && item.products.description}</small></span>
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="mw-6m text-center">
                                                                                                        <input
                                                                                                            value={
                                                                                                                item.qty
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                dynamicInputChange(
                                                                                                                    e,
                                                                                                                    index
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-control text-right"
                                                                                                            type="number"
                                                                                                            name="qty"
                                                                                                            min={
                                                                                                                0
                                                                                                            }
                                                                                                        />
                                                                                                        {/* {edit_row_index == index ? <input value={edit_sales_order_items[index].qty} onChange={e => handleEditInputRow(index, e)} className="form-control" type="number" name="qty" min={0}/> : item.qty} */}
                                                                                                    </td>
                                                                                                    <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                        {
                                                                                                            item.product_unit_name
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td
                                                                                                        className="mw-8m"
                                                                                                        align="right"
                                                                                                    >
                                                                                                        {/* <input value={item.srp} onChange={e => dynamicInputChange(e, index)} className="form-control text-right" type="number" name="srp" min={0}/> */}
                                                                                                        {NumberFormat(
                                                                                                            item.srp.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        className="mw-8m text-right uni t-price"
                                                                                                        align="right"
                                                                                                    >
                                                                                                        <input
                                                                                                            value={
                                                                                                                item.unit_price
                                                                                                                    ? item.unit_price
                                                                                                                    : item.srp
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                dynamicInputChange(
                                                                                                                    e,
                                                                                                                    index
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-control text-right"
                                                                                                            type="number"
                                                                                                            name="unit_price"
                                                                                                            min={
                                                                                                                0
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td
                                                                                                        className="min-wdth-150 max-wdth-150"
                                                                                                        align="right"
                                                                                                    >
                                                                                                        {item.amount
                                                                                                            ? NumberFormat(
                                                                                                                  item.amount.toFixed(
                                                                                                                      2
                                                                                                                  )
                                                                                                              )
                                                                                                            : NumberFormat(
                                                                                                                  item.srp.toFixed(
                                                                                                                      2
                                                                                                                  )
                                                                                                              )}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        align="center"
                                                                                                        className="mw-2m"
                                                                                                    >
                                                                                                        <span className="m-1">
                                                                                                            <button
                                                                                                                className="btn btn-sm btn-primary"
                                                                                                                onClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleDiscountItem(
                                                                                                                        index,
                                                                                                                        false
                                                                                                                    )
                                                                                                                }
                                                                                                                type="button"
                                                                                                            >
                                                                                                                <i className="ft ft-tag"></i>
                                                                                                            </button>
                                                                                                        </span>

                                                                                                        <span>
                                                                                                            <button
                                                                                                                className="btn btn-sm btn-danger"
                                                                                                                onClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleRemoveItem(
                                                                                                                        index
                                                                                                                    )
                                                                                                                }
                                                                                                                type="button"
                                                                                                            >
                                                                                                                <i className="ft ft-trash"></i>
                                                                                                            </button>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {(item.discount_type &&
                                                                                                    item
                                                                                                        .discount_type
                                                                                                        .value !==
                                                                                                        'none') ||
                                                                                                item.showDiscountRow ? (
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            colSpan={
                                                                                                                1
                                                                                                            }
                                                                                                        ></td>
                                                                                                        <td
                                                                                                            className="d-flex ali justify-content-center align-items-center"
                                                                                                            style={{
                                                                                                                gap: '5%',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span>
                                                                                                                Discount:
                                                                                                            </span>

                                                                                                            <span className="w-20 table-data__discount">
                                                                                                                {(() => {
                                                                                                                    switch (
                                                                                                                        item.discount_type
                                                                                                                            ? item
                                                                                                                                  .discount_type
                                                                                                                                  .value
                                                                                                                            : null
                                                                                                                    ) {
                                                                                                                        case 'percent':
                                                                                                                            return (
                                                                                                                                <div class="input-group">
                                                                                                                                    <input
                                                                                                                                        onChange={(
                                                                                                                                            e
                                                                                                                                        ) =>
                                                                                                                                            handleInputDiscountProduct(
                                                                                                                                                e,
                                                                                                                                                index
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        value={
                                                                                                                                            item.discount
                                                                                                                                        }
                                                                                                                                        name="discount"
                                                                                                                                        className="form-control text-right f-regular"
                                                                                                                                        type="number"
                                                                                                                                    />
                                                                                                                                    <div class="input-group-append">
                                                                                                                                        <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                            %
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        case 'amount':
                                                                                                                            return (
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                            e,
                                                                                                                                            index
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        item.discount
                                                                                                                                    }
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                            );
                                                                                                                        default: // 0 disabled
                                                                                                                            return (
                                                                                                                                <input
                                                                                                                                    value={
                                                                                                                                        item.discount
                                                                                                                                    }
                                                                                                                                    disabled
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                            );
                                                                                                                    }
                                                                                                                })()}
                                                                                                            </span>

                                                                                                            <span className="table-data__discount-dropdown">
                                                                                                                <Select
                                                                                                                    name="discount_type"
                                                                                                                    onChange={(
                                                                                                                        selectOption
                                                                                                                    ) =>
                                                                                                                        handleSelectDiscountTypeProduct(
                                                                                                                            selectOption,
                                                                                                                            index
                                                                                                                        )
                                                                                                                    }
                                                                                                                    menuPortalTarget={
                                                                                                                        document.body
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        percent_value[
                                                                                                                            item
                                                                                                                                .discount_type
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        item.discount_type
                                                                                                                    }
                                                                                                                    label="Single select"
                                                                                                                    options={
                                                                                                                        percent_value
                                                                                                                    }
                                                                                                                    isSearchable={
                                                                                                                        false
                                                                                                                    }
                                                                                                                />
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                            -{' '}
                                                                                                            {NumberFormat(
                                                                                                                Number(
                                                                                                                    item.discount_amount
                                                                                                                        ? item.discount_amount
                                                                                                                        : 0
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )
                                                                                                            )}
                                                                                                        </td>

                                                                                                        <td
                                                                                                            align="center"
                                                                                                            className="mw-2m"
                                                                                                        >
                                                                                                            <button
                                                                                                                className="btn btn-sm btn-danger"
                                                                                                                onClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleDiscountItem(
                                                                                                                        index,
                                                                                                                        true
                                                                                                                    )
                                                                                                                }
                                                                                                                type="button"
                                                                                                            >
                                                                                                                <i className="ft ft-trash"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ) : null}
                                                                                            </Fragment>
                                                                                        )
                                                                                    )}
                                                                                    {/* <tr className="bg-primary bg-accent-2">
                                                                                    <td className="min-wdth-100 max-wdth-100">{itemSelected.sku}</td>
                                                                                    <td className="min-wdth-300 max-wdth-300"><Select components={{Option}} filterOption={customFilter} classNamePrefix="lp-copy-sel" options={items} onChange={handleSelectItem} isSearchable={isSearchable} value={default_product_value}/></td>
                                                                                    <td className="min-wdth-120 max-wdth-100"><input onChange={handleInputQty} value={itemSelected.qty} name="qty" min={1} className="form-control" type="text"/></td>
                                                                                    <td className="min-wdth-100 max-wdth-100 text-center">{itemSelected.product_unit_name}</td>
                                                                                    <td className="min-wdth-150 max-wdth-150"><input onChange={handleInputSrp} value={itemSelected.srp} type="number" name="srp" className="form-control" min={1}/></td>
                                                                                    <td className="min-wdth-150 max-wdth-150" align="right"><div className="td-padding">{itemSelectedAmount ?  NumberFormat(itemSelectedAmount.toFixed(2)) : 0}</div></td>
                                                                                    <td className="text-center" width="5%"><a className="btn btn-sm btn-primary text-white" onClick={handleAddItem}>add</a></td>
                                                                                </tr> */}
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan="8"
                                                                                            className="text-left"
                                                                                        >
                                                                                            <button
                                                                                                className="btn btn-primary"
                                                                                                type="button"
                                                                                                onClick={
                                                                                                    add_items
                                                                                                }
                                                                                            >
                                                                                                <i className="la la-plus"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                5
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                Sub
                                                                                                Total
                                                                                            </div>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            {NumberFormat(
                                                                                                single_sales_order.sub_total.toFixed(
                                                                                                    2
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                5
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                Discount
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {(() => {
                                                                                                switch (
                                                                                                    single_sales_order
                                                                                                        .discount_type
                                                                                                        .value
                                                                                                ) {
                                                                                                    case 'percent':
                                                                                                        return (
                                                                                                            <div class="input-group">
                                                                                                                <input
                                                                                                                    onChange={
                                                                                                                        handleInputChangeDiscount
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        single_sales_order.discount
                                                                                                                    }
                                                                                                                    name="discount"
                                                                                                                    className="form-control text-right f-regular"
                                                                                                                    type="number"
                                                                                                                />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                        %
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    case 'amount':
                                                                                                        return (
                                                                                                            <input
                                                                                                                onChange={
                                                                                                                    handleInputChangeDiscount
                                                                                                                }
                                                                                                                value={
                                                                                                                    single_sales_order.discount
                                                                                                                }
                                                                                                                name="discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                            />
                                                                                                        );
                                                                                                    default: // 0 disabled
                                                                                                        return (
                                                                                                            <input
                                                                                                                value={
                                                                                                                    single_sales_order.discount
                                                                                                                }
                                                                                                                disabled
                                                                                                                name="discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                            />
                                                                                                        );
                                                                                                }
                                                                                            })()}
                                                                                        </td>
                                                                                        <td
                                                                                            width="10%"
                                                                                            className="text-center padding-td-5"
                                                                                        >
                                                                                            <Select
                                                                                                name="discount_type"
                                                                                                onChange={
                                                                                                    handleSelectDiscountType
                                                                                                }
                                                                                                menuPortalTarget={
                                                                                                    document.body
                                                                                                }
                                                                                                value={
                                                                                                    percent_value[
                                                                                                        single_sales_order
                                                                                                            .discount_type
                                                                                                    ]
                                                                                                }
                                                                                                defaultValue={
                                                                                                    single_sales_order.discount_type
                                                                                                }
                                                                                                label="Single select"
                                                                                                options={
                                                                                                    percent_value
                                                                                                }
                                                                                                isSearchable={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                5
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                VAT
                                                                                                (
                                                                                                {
                                                                                                    single_sales_order.tax
                                                                                                }{' '}
                                                                                                %)
                                                                                            </div>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            {NumberFormat(
                                                                                                single_sales_order.vat_amount.toFixed(
                                                                                                    2
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="padding-td-5 min-width-150">
                                                                                            <Select
                                                                                                name="tax_type"
                                                                                                onChange={
                                                                                                    handleTaxType
                                                                                                }
                                                                                                value={
                                                                                                    single_sales_order.tax_type
                                                                                                }
                                                                                                menuPortalTarget={
                                                                                                    document.body
                                                                                                }
                                                                                                options={
                                                                                                    tax_types
                                                                                                }
                                                                                                isSearchable={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                5
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                Delivery
                                                                                                Fee
                                                                                            </div>
                                                                                        </td>
                                                                                        <td width="12%">
                                                                                            {isOther ? (
                                                                                                <input
                                                                                                    onChange={
                                                                                                        handleInputAttr
                                                                                                    }
                                                                                                    value={
                                                                                                        edit_attr.other
                                                                                                    }
                                                                                                    name="other"
                                                                                                    className="form-control"
                                                                                                    type="number"
                                                                                                />
                                                                                            ) : (
                                                                                                <div align="right">
                                                                                                    {' '}
                                                                                                    {
                                                                                                        single_sales_order.other
                                                                                                    }{' '}
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {isOther ? (
                                                                                                <Fragment>
                                                                                                    <div className="btn-group">
                                                                                                        <a
                                                                                                            className="btn btn-primary btn-sm"
                                                                                                            onClick={() =>
                                                                                                                handleAttrSubmit(
                                                                                                                    'other'
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <i className="ft ft-check text-white"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            ) : (
                                                                                                <a
                                                                                                    className="btn btn-info btn-sm"
                                                                                                    onClick={() =>
                                                                                                        toggleEditBtn(
                                                                                                            true,
                                                                                                            'isOther'
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i className="ft ft-edit text-white"></i>
                                                                                                </a>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                5
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                <strong>
                                                                                                    Total
                                                                                                </strong>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            {NumberFormat(
                                                                                                single_sales_order.total.toFixed(
                                                                                                    2
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </Fragment>
                                                                    )}

                                                                    <div className="col-12 mb-2"></div>

                                                                    <div className="row f-regular">
                                                                        <div className="col-md-4">
                                                                            <label>
                                                                                Remarks{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <textarea
                                                                                        onChange={
                                                                                            handleInputChange
                                                                                        }
                                                                                        value={
                                                                                            single_sales_order.remarks
                                                                                        }
                                                                                        name="remarks"
                                                                                        rows="2"
                                                                                        className="form-control f-regular"
                                                                                    ></textarea>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Payment
                                                                                Mode{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            payment_type_options
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectPaymentType
                                                                                        }
                                                                                        value={
                                                                                            single_sales_order.payment_type
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Payment
                                                                                Terms{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            terms_conditions
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectTermsConditions
                                                                                        }
                                                                                        value={
                                                                                            single_sales_order.payment_terms
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                        <div
                                                                                            className="a-tagicon icon-s"
                                                                                            onClick={() =>
                                                                                                setModal(
                                                                                                    'payment_terms_modal',
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft ft-plus-circle text-primary"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <div className="form-group">
                                                                                <label>
                                                                                    Receipt
                                                                                    Type{' '}
                                                                                    <span className="text-danger">
                                                                                        *
                                                                                    </span>
                                                                                </label>
                                                                                <div className="form-group row">
                                                                                    <div className="col-md-10 col-10 pr-0">
                                                                                        <Select
                                                                                            options={
                                                                                                receipt_type_options
                                                                                            }
                                                                                            onChange={
                                                                                                handleSelectReceiptType
                                                                                            }
                                                                                            value={
                                                                                                single_sales_order.receipt_type
                                                                                            }
                                                                                            isSearchable={
                                                                                                isSearchable
                                                                                            }
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                                        <div className="input-group-prepend">
                                                                                            <span
                                                                                                href="#"
                                                                                                className="tool-tip text-primary"
                                                                                                data-tip="this is a tool tip"
                                                                                            >
                                                                                                <i className="la la-question-circle"></i>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </form>
                                                ) : (
                                                    <SalesOrderViewTable />
                                                )}
                                                <div className="row justify-content-end mt-3">
                                                    <div className="mr-2">
                                                        <div className="form-group">
                                                            {single_sales_order.status !=
                                                                'saved' &&
                                                                single_sales_order.status !=
                                                                    'delivered' &&
                                                                single_sales_order.status !=
                                                                    'cancelled' && (
                                                                    <Fragment>
                                                                        {/* <Show when="inv.sales.sales-orders.draft"><button onClick={() => handleOnSubmit('update', 'draft')} type="button" className="btn btn-outline-light text-dark">Draft</button></Show>{" "}
                                                                <button onClick={() => handleOnSubmit('create', 'saved-email')} type="button" className="btn btn-blue">Save & Email</button>{" "}
                                                                {
                                                                    (edit_row_index != null || isDiscount) ? <button type="button" className="btn btn-primary btn-save" disabled>Save</button>
                                                                    : <button onClick={() => handleOnSubmit('update', 'saved')} className="btn btn-primary btn-save">Save</button>
                                                                }
                                                                 */}
                                                                        <Show when="inv.sales.sales-orders.draft">
                                                                            <div className="btn-group btn-dpd">
                                                                                <button
                                                                                    className="btn btn-default btn-outline-light text-dark btn-main"
                                                                                    onClick={() =>
                                                                                        handleOnSubmit(
                                                                                            'update',
                                                                                            'draft'
                                                                                        )
                                                                                    }
                                                                                    type="button"
                                                                                >
                                                                                    Save
                                                                                    as
                                                                                    Draft
                                                                                </button>
                                                                                <button
                                                                                    data-toggle="dropdown"
                                                                                    className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                                    type="button"
                                                                                >
                                                                                    <span className="caret"></span>
                                                                                </button>
                                                                                <ul className="dropdown-menu">
                                                                                    {/* <li><a className="dpd-menu" onClick={() => handleOnSubmit('update', 'draft')}>Daft</a></li> */}
                                                                                    <li>
                                                                                        <a
                                                                                            className="dpd-menu"
                                                                                            onClick={() =>
                                                                                                handleOnSubmit(
                                                                                                    'update',
                                                                                                    'draft-email'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Draft
                                                                                            &
                                                                                            Email
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a
                                                                                            className="dpd-menu"
                                                                                            onClick={() =>
                                                                                                handleOnSubmit(
                                                                                                    'update',
                                                                                                    'draft-print'
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Draft
                                                                                            &
                                                                                            Print
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </Show>{' '}
                                                                        <div className="btn-group btn-dpd">
                                                                            <button
                                                                                className="btn btn-primary  btn-main"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'update',
                                                                                        'saved'
                                                                                    )
                                                                                }
                                                                                type="button"
                                                                            >
                                                                                Save
                                                                                Order
                                                                            </button>
                                                                            <button
                                                                                data-toggle="dropdown"
                                                                                className="btn btn-primary dropdown-toggle btn-carret"
                                                                                type="button"
                                                                            >
                                                                                <span className="caret"></span>
                                                                            </button>
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    // (edit_row_index != null || isDiscount) &&
                                                                                    // <li><a className="dpd-menu" href="#" onClick={() => handleOnSubmit('update', 'saved')}>Save</a></li>
                                                                                }
                                                                                <li>
                                                                                    <a
                                                                                        className="dpd-menu"
                                                                                        onClick={() =>
                                                                                            handleOnSubmit(
                                                                                                'update',
                                                                                                'saved-email'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                        &
                                                                                        Email
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a
                                                                                        className="dpd-menu"
                                                                                        onClick={() =>
                                                                                            handleOnSubmit(
                                                                                                'update',
                                                                                                'saved-print'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                        &
                                                                                        Print
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Fragment>
                                                                )}
                                                            {single_sales_order.status ==
                                                                'saved' &&
                                                                single_sales_order.status !=
                                                                    'cancelled' && (
                                                                    <Fragment>
                                                                        <Show when="inv.sales.sales-orders.cancel">
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                onClick={
                                                                                    cancelSi
                                                                                }
                                                                                type="button"
                                                                            >
                                                                                {btn_form.cancel ? (
                                                                                    <i className="icon-loading-custom"></i>
                                                                                ) : (
                                                                                    <span>
                                                                                        Cancel
                                                                                    </span>
                                                                                )}
                                                                            </button>
                                                                        </Show>
                                                                    </Fragment>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <SalesOrderCustomerModal
                    show={customer_modal}
                    onHide={() => setModal('customer_modal', false)}
                />
                <PaymentTermsModal
                    show={payment_terms_modal}
                    onHide={() => setModal('payment_terms_modal', false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    sales_order: state.sales_order,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    handleOnSubmit,
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleSelectSalesOrderTemplate,
    handleSelectReceiptType,
    getOrderTemplates,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    setModal,
    getTermsConditions,
    getCustomers,
    getProducts,
    setPage,
    getSingleSalesOrder,
    handleSelectPaymentType,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    SIDatePicker,
    add_items,
    dynamicInputChange,
    blockRoute,
    searchProducst,
    ShowComboBox,
    cancelSi,
    computationHandler,
    handleTaxType,
    handleSelectQuotation,
    getQuotations,
})(SalesOrderEdit);
